@mixin fadeLeftInMixin($duration)
  -webkit-animation-name: fadeInLeft
  animation-name: fadeInLeft
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

@mixin fadeInRightMixin($duration)
  -webkit-animation-name: fadeInRight
  animation-name: fadeInRight
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

@mixin fadeOutRightMixin($duration)
  -webkit-animation-name: fadeOutRight
  animation-name: fadeOutRight
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

@mixin  fadeOutLeftMixin($duration)
  -webkit-animation-name: fadeOutLeft
  animation-name: fadeOutLeft
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

@mixin bounceInMixin($duration, $delay)
  -webkit-animation-name: bounceIn
  animation-name: bounceIn
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-fill-mode: both
  animation-fill-mode: both
  animation-delay: $delay

@mixin bounceOutMixin($duration)
  -webkit-animation-name: bounceOut
  animation-name: bounceOut
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-duration: 1s
  animation-duration: 1s
  -webkit-animation-fill-mode: both
  animation-fill-mode: both
@mixin zoomInUpMixin($duration, $delay)
  -webkit-animation-name: zoomInUp
  animation-name: zoomInUp
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-fill-mode: both
  animation-fill-mode: both
  animation-delay: $delay

@mixin fadeInMixin($duration , $delay)
  -webkit-animation-name: fadeIn
  animation-name: fadeIn
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-fill-mode: both
  animation-fill-mode: both
  animation-delay: $delay

@mixin fadeOutMixin($duration , $delay)
  -webkit-animation-name: fadeOut
  animation-name: fadeOut
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-fill-mode: both
  animation-fill-mode: both
  animation-delay: $delay

@mixin fadeInUpMixin($duration,$delay)
  -webkit-animation-name: fadeInUp
  animation-name: fadeInUp
  -webkit-animation-duration: $duration
  animation-duration: $duration
  animation-delay: $delay
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

@mixin fadeInUpNoOpacityMixin($duration,$delay)
  -webkit-animation-name: fadeInUpNoOpacity
  animation-name: fadeInUpNoOpacity
  -webkit-animation-duration: $duration
  animation-duration: $duration
  animation-delay: $delay
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

@mixin fadeWidthMixin($duration,$delay)
  -webkit-animation-name: fadeWidth
  animation-name: fadeWidth
  -webkit-animation-duration: $duration
  animation-duration: $duration
  animation-delay: $delay
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

@mixin fadeInRightNoOpacityMixin($duration)
  -webkit-animation-name: fadeInRightNoOpacity
  animation-name: fadeInRightNoOpacity
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

@mixin fadeLeftInNoOpacityMixin($duration)
  -webkit-animation-name: fadeInLeftNoOpacity
  animation-name: fadeInLeftNoOpacity
  -webkit-animation-duration: $duration
  animation-duration: $duration
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

  @-webkit-keyframes fadeInLeft
    0% 
    opacity: 0
    -webkit-transform: translate3d(-100%, 0, 0)
    transform: translate3d(-100%, 0, 0)

    100% 
    opacity: 1
    -webkit-transform: none
    transform: none

  @keyframes fadeInLeft
    0%
      opacity: 0
      -webkit-transform: translate3d(-100%, 0, 0)
      transform: translate3d(-100%, 0, 0)

    100%
      opacity: 1
      -webkit-transform: none
      transform: none

  @-webkit-keyframes fadeInLeftNoOpacity
    0% 
    -webkit-transform: translate3d(-450%, 0, 0)
    transform: translate3d(-450%, 0, 0)

    100% 
    -webkit-transform: none
    transform: none

  @keyframes fadeInLeftNoOpacity
    0%
      -webkit-transform: translate3d(-450%, 0, 0)
      transform: translate3d(-450%, 0, 0)

    100%
      -webkit-transform: none
      transform: none

          // fadeInRight

  @-webkit-keyframes fadeInRight
    0% 
    opacity: 0
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)

    100% 
    opacity: 1
    -webkit-transform: none
    transform: none

  @keyframes fadeInRight
    0%
      opacity: 0
      -webkit-transform: translate3d(100%, 0, 0)
      transform: translate3d(100%, 0, 0)

    100%
      opacity: 1
      -webkit-transform: none
      transform: none
  @-webkit-keyframes fadeInRightNoOpacity
    0% 
    -webkit-transform: translate3d(450%, 0, 0)
    transform: translate3d(450%, 0, 0)

    100% 
    -webkit-transform: none
    transform: none

  @keyframes fadeInRightNoOpacity
    0%
      -webkit-transform: translate3d(450%, 0, 0)
      transform: translate3d(450%, 0, 0)

    100%
      -webkit-transform: none
      transform: none

        // fadeOutRight

  @-webkit-keyframes fadeOutRight
    0% 
    opacity: 1

    100% 
    opacity: 0
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)

  @keyframes fadeOutRight
    0%
      opacity: 1

    100%
      opacity: 0
      -webkit-transform: translate3d(100%, 0, 0)
      transform: translate3d(100%, 0, 0)

    //////fadeOutLeft

  @-webkit-keyframes fadeOutLeft
    0% 
    opacity: 1

    100% 
    opacity: 0
    -webkit-transform: translate3d(-100%, 0, 0)
    transform: translate3d(-100%, 0, 0)

  @keyframes fadeOutLeft
    0%
      opacity: 1

    100%
      opacity: 0
      -webkit-transform: translate3d(-100%, 0, 0)
      transform: translate3d(-100%, 0, 0)

  @-webkit-keyframes bounceIn
    0%, 20%, 40%, 60%, 80%, 100% 
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)

    0% 
    // opacity: 0
    -webkit-transform: scale3d(.3, .3, .3)
    transform: scale3d(.3, .3, .3)

    20% 
    -webkit-transform: scale3d(1.1, 1.1, 1.1)
    transform: scale3d(1.1, 1.1, 1.1)

    40% 
    -webkit-transform: scale3d(.9, .9, .9)
    transform: scale3d(.9, .9, .9)

    60% 
    // opacity: 1
    -webkit-transform: scale3d(1.03, 1.03, 1.03)
    transform: scale3d(1.03, 1.03, 1.03)

    80% 
    -webkit-transform: scale3d(.97, .97, .97)
    transform: scale3d(.97, .97, .97)

    100% 
    // opacity: 1
    -webkit-transform: scale3d(1, 1, 1)
    transform: scale3d(1, 1, 1)

  @keyframes bounceIn
    0%, 20%, 40%, 60%, 80%, 100%
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
      transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)

    0%
      // opacity: 0
      -webkit-transform: scale3d(.3, .3, .3)
      transform: scale3d(.3, .3, .3)

    20%
      -webkit-transform: scale3d(1.1, 1.1, 1.1)
      transform: scale3d(1.1, 1.1, 1.1)

    40%
      -webkit-transform: scale3d(.9, .9, .9)
      transform: scale3d(.9, .9, .9)

    60%
      // opacity: 1
      -webkit-transform: scale3d(1.03, 1.03, 1.03)
      transform: scale3d(1.03, 1.03, 1.03)

    80%
      -webkit-transform: scale3d(.97, .97, .97)
      transform: scale3d(.97, .97, .97)

    100%
      // opacity: 1
      -webkit-transform: scale3d(1, 1, 1)
      transform: scale3d(1, 1, 1)

    // bounceOut

  @-webkit-keyframes bounceOut
    0% 
    -webkit-transform: scale3d(.9, .9, .9)
    transform: scale3d(.9, .9, .9)

    0%, 55% 
    opacity: 1
    -webkit-transform: scale3d(1.1, 1.1, 1.1)
    transform: scale3d(1.1, 1.1, 1.1)

    100% 
    opacity: 0
    -webkit-transform: scale3d(.3, .3, .3)
    transform: scale3d(.3, .3, .3)

  @keyframes bounceOut
    20%
      -webkit-transform: scale3d(.9, .9, .9)
      transform: scale3d(.9, .9, .9)

    50%, 55%
      opacity: 1
      -webkit-transform: scale3d(1.1, 1.1, 1.1)
      transform: scale3d(1.1, 1.1, 1.1)

    100%
      opacity: 0
      -webkit-transform: scale3d(.3, .3, .3)
      transform: scale3d(.3, .3, .3)

  @-webkit-keyframes zoomInUp
    0% 
    // opacity: 0
    -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0)
    transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)

    60% 
    // opacity: 1
    -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0)
    transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)

  @keyframes zoomInUp
    0%
      // opacity: 0
      -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0)
      transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0)
      -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
      animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)

    60%
      // opacity: 1
      -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0)
      transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0)
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)

  @-webkit-keyframes fadeIn
    0% 
    opacity: 0
    100% 
    opacity: 1
  @keyframes fadeIn
    0%
      opacity: 0
    100%
      opacity: 1
  @-webkit-keyframes fadeOut
    0% 
    opacity: 1
    100% 
    opacity: 0
  @keyframes fadeOut
    0%
      opacity: 1
    100%
      opacity: 0

  @-webkit-keyframes fadeInUp
    0% 
    opacity: 0
    -webkit-transform: translate3d(0, 100%, 0)
    transform: translate3d(0, 100%, 0)

    100% 
    opacity: 1
    -webkit-transform: none
    transform: none

  @keyframes fadeInUp
    0%
      opacity: 0
      -webkit-transform: translate3d(0, 100%, 0)
      transform: translate3d(0, 100%, 0)

    100%
      opacity: 1
      -webkit-transform: none
      transform: none

  @-webkit-keyframes fadeInUpNoOpacity
    0% 

    -webkit-transform: translate3d(0, 200%, 0)
    transform: translate3d(0, 200%, 0)

    100% 

    -webkit-transform: none
    transform: none

  @keyframes fadeInUpNoOpacity
    0%

      -webkit-transform: translate3d(0, 200%, 0)
      transform: translate3d(0, 200%, 0)

    100%

      -webkit-transform: none
      transform: none

  @keyframes fadeWidth
    0%
      width: 0%

    100%
      width: 100%

.bounceOut
  @include bounceOutMixin(1s)
.fadeInLeft
  @include fadeLeftInMixin(1s)
.bounceIn
  @include bounceInMixin(1s, 0s)
.fadeOutLeft
  @include fadeOutLeftMixin(1s)
.fadeOutRight
  @include fadeOutRightMixin(1s)
.fadeInRight
  @include fadeInRightMixin(1s)
.fadeInLeft
  @include fadeLeftInMixin(1s)
.zoomInUp
  @include zoomInUpMixin(1s,0.5s)
.fadeIn
  @include fadeInMixin(1s,0.5s)
.fadeOut
  @include fadeOutMixin(1s,0.5s)
.fadeInUp
  @include fadeInUpMixin(1s,0s)
.fadeWidth
  @include fadeWidthMixin(1s,0s)
