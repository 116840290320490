.copy_btn
    width: 30px
    height: 30px
    border-radius: 4px
    background-color: rgba(255, 255, 255,0.07)
    background-image: url('../../assets/Images/Home/files.svg')
    background-size: 60% 60%
    background-position: center
    background-repeat: no-repeat
    border: none
    transition: background 0.5s
    cursor: pointer

    &:hover
        background-color: rgba(255, 255, 255,0.15)

@media screen and (max-width: 1185px)
    .copy_btn
        width: 20px
        height: 20px
@media screen and (max-width: 480px)
    .copy_btn
        width: 15px
        height: 15px
