@import url("./assets/Fonts/fonts.css");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* cursor: url('./assets/Images/cursor3.png') , auto; */
}

* ::selection {
  background-color: rgba(255, 138, 54, 1);
  color: #ffffff;
}

:root {
  --whitecolor: rgba(255, 255, 255, 1);
}

body {
  margin: 0;
  font-family: "Gilroy", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(255, 255, 255, 1);
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  background: #131924;
}

body::-webkit-scrollbar-thumb {
  background: rgba(255, 138, 54, 1);
  border-radius: 5px;
}
