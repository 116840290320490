//320px
#howtoplay__section_wrapper
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    min-height: 800px
    justify-content: center
    overflow: hidden
    .howtoplay__title
        font-family: Gilroy Bold
        font-size: 24px
        line-height: 23.76px
        color: #FFFFFF
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.6)

    .howtoplay__content
        opacity: 1
        // display: none
        display: flex
        flex-direction: column
        gap: 10px

    .howtoplay__card_wrapper
        margin-top: 50px
        display: flex
        flex-direction: column
        position: relative

        .howtoplay__card_index
            position: absolute
            font-family: Gilroy Heavy
            font-size: 100px
            line-height: 99px
            letter-spacing: 0.03em
            color: rgb(255, 138, 54)
            top: -29%
            left: -8%

        .howtoplay__card_blockwrapper
            position: relative
            width: 214.39px
            height: 187.29px

            .howtoplay__card_clippath--block
                position: relative
                border-radius: 8px
                width: 100%
                height: 100%
                border: rgba(255, 138, 54, 1) solid 1px
                background: rgba(255, 255, 255, 0.1)
                clip-path: polygon(25% -6%, 100% -3%, 100% 100%, -30% 100%)
                backdrop-filter: blur(10px)

            .howtoplay__card_content
                position: absolute
                display: flex
                width: 100%
                height: 100%
                top: 0

                .howtoplay__persongta
                    width: 155.12px
                    height: 198.18px
                    position: absolute
                    top: -6%
                    left: -13%

                .howtoplay__card_text
                    width: 70%
                    height: 100%
                    margin-left: auto
                    padding: 11px 8px 0px 0px
                    display: flex
                    flex-direction: column

                    .howtoplay__card_title
                        font-size: 16px
                        font-weight: 700
                        line-height: 16.8px
                        letter-spacing: -0.02em
                        text-align: end
                        z-index: 1

                    .howtoplay__card_description
                        font-size: 11px
                        font-weight: 500
                        line-height: 10.5px
                        letter-spacing: -0.02em
                        text-align: right
                        color: rgba(255, 255, 255, 0.6)
                        width: 80%
                        margin-left: auto
                        margin-top: 10px
                        font-family: Gilroy

                    .howtoplay__card_button
                        width: 106.96px
                        height: 22.82px
                        margin-left: auto
                        background: rgba(255, 138, 54, 1)
                        border: none
                        border-radius: 4px
                        font-family: Gilroy
                        font-size: 12px
                        font-weight: 800
                        line-height: 80%
                        color: rgba(255, 255, 255, 1)
                        position: absolute
                        bottom: 5%
                        right: 4%
                        transition: box-shadow 0.5s
                        cursor: pointer

                        &:hover
                            box-shadow: 0px 0px 20px 0px rgba(255, 138, 54, 0.5)
