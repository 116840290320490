.burger {
  position: relative;
  width: 30px;
  height: 18px;
  background: transparent;
  cursor: pointer;
  display: block;
  z-index: 9999 !important;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  width: 60%;
  margin-left: 39%;
  transform-origin: left center;
  transform: translateY(-100%);
}

.burger input:checked ~ span:nth-of-type(1) {
  transform: rotate(45deg);
  top: 0px;
  left: 5px;
}

.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked ~ span:nth-of-type(3) {
  transform: rotate(-45deg);
  width: 100%;
  top: 21px;
  left: 5px;
  margin-left: 0;
}

@media screen and (max-width: 480px) {
  .burger {
    width: 20px;
    height: 12px;
  }

  .burger span {
    height: 2px;
  }

  .burger input:checked ~ span:nth-of-type(3) {
    top: 14px;
  }
}
