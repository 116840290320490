@media screen and (min-width: 480px)
    #howtoplay__section_wrapper
        min-height: 1100px
        .howtoplay__title
            font-size: 30px
            line-height: 29.7px

        .howtoplay__card_wrapper
            margin-top: 65px
            .howtoplay__card_index
                top: -29%
                left: -8%
                font-size: 150px
                line-height: 148.5px

            .howtoplay__card_blockwrapper
                width: 279.6px
                height: 244.27px

                .howtoplay__card_clippath--block
                    border-radius: 8px

                .howtoplay__card_content
                    .howtoplay__persongta
                        position: absolute
                        top: -6%
                        left: -13%
                        width: 198.39px
                        height: 259.77px

                    .howtoplay__card_text
                        padding: 14px 11px 0px 0px
                        .howtoplay__card_title
                            font-size: 20px
                            line-height: 21px

                        .howtoplay__card_description
                            margin-top: 12px
                            font-size: 12px
                            line-height: 12.6px
                            letter-spacing: -0.02em

                        .howtoplay__card_button
                            width: 139.49px
                            height: 29.76px
                            font-size: 16px
                            line-height: 80%

@media screen and (min-width: 768px)
    #howtoplay__section_wrapper
        min-height: 850px

        .howtoplay__title
            font-size: 40px
            line-height: 39.6px

        .howtoplay__content
            opacity: 1
            display: flex
            flex-wrap: wrap
            flex-direction: row
            width: 100%
            align-items: center
            max-width: 650px
            justify-content: center
            column-gap: 45px

        .howtoplay__card_wrapper
            margin-top: 77px

        .howtoplay__card_index
            top: -29%
            left: -8%
            font-size: 150px
            line-height: 148.5px

        .howtoplay__card_blockwrapper
            .howtoplay__card_clippath--block
                border-radius: 14px
            .howtoplay__card_content
                .howtoplay__persongta
                    .howtoplay__card_text
                        padding: 16px 15px 0px 0px
                        .howtoplay__card_button
                            bottom: 6%

@media screen and (min-width: 1024px)
    #howtoplay__section_wrapper
        min-height: 700px
        .howtoplay__content
            max-width: 100%
            column-gap: 48px
        .howtoplay__card_wrapper
            margin-top: 85px

@media screen and (min-width: 1920px)
    #howtoplay__section_wrapper
        min-height: 900px
        .howtoplay__title
            font-size: 70px
            line-height: 69.3px

        .howtoplay__content
            column-gap: 79px

        .howtoplay__card_wrapper
            margin-top: 126px
            .howtoplay__card_index
                top: -29%
                left: -8%
                font-size: 230px
                line-height: 227.7px

            .howtoplay__card_blockwrapper
                width: 451px
                height: 394px

                .howtoplay__card_clippath--block
                    border-radius: 16px

                .howtoplay__card_content
                    .howtoplay__persongta
                        position: absolute
                        top: -6%
                        left: -13%
                        width: 320px
                        height: 419px

                    .howtoplay__card_text
                        padding: 24px 20px 0px 0px
                        .howtoplay__card_title
                            font-size: 32px
                            line-height: 33.6px
                            letter-spacing: -0.02em

                        .howtoplay__card_description
                            margin-top: 20px
                            font-size: 20px
                            line-height: 21px
                            letter-spacing: -0.02em

                        .howtoplay__card_button
                            width: 225px
                            height: 48px
                            font-size: 24px
                            line-height: 80%
                            border-radius: 8px
