@mixin width ($duration)
    -webkit-animation-name: fadeInRight
    animation-name: fadeInRight
    -webkit-animation-duration: $duration
    animation-duration: $duration
    -webkit-animation-fill-mode: both
    animation-fill-mode: both

@media screen and (min-width: 220px)
    .media_box-main
        width: 100%
        height: max-content
        .media_box-title
            position: relative
            width: 130px
            height: 20px
            font-family: 'Gilroy Bold'
            background: rgba(227, 89, 89, 0.11)
            border-radius: 0px 10px 0px 0px
            display: flex
            justify-content: center
            align-items: flex-end
            &::before
                width: 100px
                height: 100px
                border-radius: 100%
                position: absolute
                left: 10px
                top: 10px
                transform: rotate(45deg)
                border: 10px solid blue
                clip: rect(0px, auto, 20px, 0px)

            .media_title
                font-size: 12px
                font-weight: 800
                line-height: 14.7px

        .media_box-info
            border-radius: 0px 10px 10px 0px
            position: relative
            display: grid
            grid-template-columns: repeat(1, auto)
            grid-template-rows: auto auto
            gap: 10px
            justify-content: start
            align-items: center
            height: 189px
            background: rgba(227, 89, 89, 0.11)
            .media_img
                position: absolute
                right: -55px
                width: 140px
                height: 110px
                object-fit: contain

            .media_item-info
                color: rgba(255, 255, 255, 0.5)
                display: flex
                align-items: center
                padding-left: 17px
                .media_arrow-short
                    display: inline
                    width: 36px
                    height: 36px
                    margin-right: 5px
                .media_arrow
                    display: none
                .media_box-text
                    width: 185px
                    display: flex
                    flex-direction: column
                    .media_text
                        font-size: 11px
                        font-weight: 400
                        line-height: 12.76px
                        .media_friend
                            color: rgba(255, 255, 255, 0.9)
                    .button-form
                        margin-top: 7px
                        font-family: 'Gilroy'
                        font-size: 20px
                        font-weight: 500
                        line-height: 23.54px
                        border-radius: 4px
                        height: 14px
                        width: 98px
                        background: rgba(146, 56, 68, 0.38)
                        border: 1px solid rgba(255, 255, 255, 0.15)
                        text-align: center
                        color: white
                        font-size: 10px
                        font-weight: 500
                        line-height: 11.77px
                        cursor: pointer
                        transition: background 0.3s ease , border 0.3s ease , color 0.3s ease

                        &:hover
                            background: rgba(255, 138, 54, 1)
                            border: 1px solid rgba(255, 138, 54, 1)
                            color: white

@media screen and (min-width: 480px)
    .media_box-main

        .media_box-title
            height: 25px
            width: 190px
            .media_title
                font-size: 18px
                font-weight: 800
                line-height: 22.05px

        .media_box-info
            height: 124px
            grid-template-columns: repeat(2, auto)
            grid-template-rows: auto auto
            .media_img
                width: 161px
                height: 122px
                right: -70px
                bottom: -20px

            .media_item-info
                padding-left: 0px
                &:nth-of-type(3)

                    grid-column: 1 / span 2
                    grid-row: 2
                    justify-self: center

                .media_arrow-short
                    width: 40px
                    height: 40px
                .media_arrow

                .media_box-text
                    width: 150px
                    .media_text
                        font-size: 12px
                        font-weight: 400
                        line-height: 13.92px
                    .button-form
                        width: 98px
                        height: 14px
                        font-size: 8px
                        font-weight: 500
                        line-height: 9.42px
                        margin-top: 4px

@media screen and (min-width: 768px)
    .media_box-main

        .media_box-title
            border-radius: 0px 16px 0px 0px
            width: 250px
            height: 30px
            .media_title
                font-size: 21px
                line-height: 25.73px

        .media_box-info
            border-radius: 0px 16px 16px 0px
            height: 127px
            display: grid
            grid-template-columns: repeat(3, auto)
            grid-template-rows: auto auto
            justify-content: flex-start
            .media_img
                width: 230px
                height: 133px

                top: 50%
                right: -50px
                transform: translate(0 , -50%)
            .media_item-info
                &:nth-of-type(3)

                    grid-column: 3
                    grid-row: 1
                    justify-self: center

                .media_arrow-short
                    width: 57px
                    height: 57px
                .media_arrow

                .media_box-text
                    width: 120px
                    .media_text

                        font-size: 14px
                        line-height: 16.24px
                        text-align: left
                    .button-form
                        height: 21px
                        font-size: 11px
                        font-weight: 500
                        line-height: 12.95px
@media screen and (min-width: 1024px)
    .media_box-main

        .media_box-title
            width: 300px
            height: 40px
            .media_title
                margin-top: 10px
                font-size: 26px
                line-height: 31.85px

        .media_box-info
            height: 180px
            .media_img
                width: 324px
                height: 243px
                right: -140px
                object-fit: contain
            .media_item-info

                .media_arrow-short
                    display: inline
                    width: 80px
                    height: 70px
                .media_arrow
                    display: none
                .media_box-text
                    width: 185px
                    .media_text
                        font-size: 22px
                        line-height: 25.52px

                    .button-form
                        margin-top: 12px
                        font-family: 'Gilroy'
                        font-size: 20px
                        font-weight: 500
                        line-height: 23.54px
                        border-radius: 8px
                        height: 33px
                        width: 100%
                        background: rgba(146, 56, 68, 0.38)
                        border: 1px solid rgba(255, 255, 255, 0.15)
                        text-align: center
                        color: white

@media screen and (min-width: 1920px)
    .media_box-main
        width: 100%
        height: 226px
        .media_box-title
            width: 470px
            height: 50px
            background: rgba(227, 89, 89, 0.11)
            border-radius: 16px 16px 0px 0px
            display: flex
            justify-content: center
            .media_title
                font-family: 'Gilroy Bold'
                font-size: 42px
                font-weight: 800
                line-height: 51.45px
                text-align: left

        .media_box-info
            position: relative
            display: grid
            grid-template-columns: repeat(3, auto)
            gap: 10px
            justify-content: start
            align-items: center
            height: 176px
            background: rgba(227, 89, 89, 0.11)
            .media_img
                position: absolute
                right: -120px
                width: 435px
                height: 253px

            .media_item-info
                display: flex
                align-items: center
                .media_arrow-short
                    display: none
                .media_arrow
                    display: block
                    width: 115px
                    height: 123px
                    margin-right: 10px
                .media_box-text
                    width: 300px
                    display: flex
                    flex-direction: column
                    .media_text
                        font-size: 24px
                        font-weight: 400
                        line-height: 27.84px
                        text-align: left
                    .button-form
                        margin-top: 12px
                        font-family: 'Gilroy'
                        font-size: 20px
                        font-weight: 500
                        line-height: 23.54px
                        border-radius: 8px
                        height: 33px
                        width: 100%
                        background: rgba(146, 56, 68, 0.38)
                        border: 1px solid rgba(255, 255, 255, 0.15)
                        text-align: center
                        color: white
