:root {
  --white-text: rgba(255, 255, 255, 1);
  --main-color: rgba(255, 138, 54, 1);
  --pale-text: rgba(255, 255, 255, 0.7);
  --home-video-drop-shadow: drop-shadow(0px 0px 10px rgba(255, 138, 54, 0.8));
  --socials-item-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 11.43%,
    rgba(255, 255, 255, 0.1) 100%
  );
  --socials-item-active-gradient: linear-gradient(
    180deg,
    rgba(255, 138, 54, 0) 11.43%,
    rgba(255, 138, 54, 0.33) 100%
  );
  --border-color: rgba(255, 255, 255, 0.15);
  --footer-root-text-color: rgba(255, 255, 255, 0.3);
}
