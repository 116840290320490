@media screen and (min-width: 220px)
    .box-img
        position: absolute
        width: 100%
        height: 100%
        pointer-events: none

    .top-position
        z-index: 10

    .coin1
        position: absolute
        width: 39px
        height: 30px
        z-index: 10
        top: 30px
        left: -40px

    .coin2
        position: absolute
        width: 35.1px
        height: 27px
        top: 150px
        left: -50px
        transform: rotate(-20deg)
    .coin3
        position: absolute
        width: 42px
        height: 33px
        top: -10px
        left: 100px
        transform: rotate(-40deg)
        filter: brightness(60%)

    .coin4
        position: absolute
        width: 60px
        height: 45px
        top: -40px
        left: 60px
        transform: rotate(10deg)
        filter: brightness(60%) blur(3px)

    .coin5
        position: absolute
        width: 54px
        height: 40.5px
        top: -20px
        left: -80px
        transform: rotate(-50deg)
        filter: brightness(60%) blur(3px)

    .coin6
        position: absolute
        width: 99px
        height: 72px
        top: 180px
        left: -60px
        z-index: 10
        transform: rotate(10deg)
        filter: blur(2px)

    .coin7
        position: absolute
        width: 60px
        height: 45px
        top: 60px
        left: -90px
        transform: rotate(10deg)
        filter: brightness(60%) blur(4px)

@media screen and (min-width: 1024px)
    .coin1
        width: 59px
        height: 45px
        z-index: 10
        top: 60px
    .coin2
        width: 45.1px
        height: 37px
        top: 10px
        left: -150px
    .coin3
        width: 52px
        height: 43px
        top: -10px
        left: 160px
    .coin4
        width: 60px
        height: 45px
        top: 0px
        left: 260px
    .coin6
        position: absolute
        width: 109px
        height: 82px
        top: 300px
        left: -50px
        z-index: 10
        transform: rotate(10deg)
        filter: blur(2px)
    .coin7
        width: 60px
        height: 45px
        top: 200px
        left: -130px
@media screen and (min-width: 1920px)
    .box-img
        position: absolute
        width: 100%
        height: 100%
        pointer-events: none

    .top-position
        z-index: 10

    .coin1
        position: absolute
        width: 130px
        height: 100px
        z-index: 10
        top: 30px
        left: -90px

    .coin2
        position: absolute
        width: 117px
        height: 90px
        top: 150px
        left: -200px
        transform: rotate(-20deg)
    .coin3
        position: absolute
        width: 140px
        height: 110px
        top: -10px
        left: 400px
        transform: rotate(-40deg)
        filter: brightness(60%)

    .coin4
        position: absolute
        width: 200px
        height: 150px
        top: -90px
        left: 220px
        transform: rotate(10deg)
        filter: brightness(60%) blur(3px)

    .coin5
        position: absolute
        width: 180px
        height: 135px
        top: -90px
        left: -240px
        transform: rotate(-50deg)
        filter: brightness(60%) blur(3px)

    .coin6
        position: absolute
        width: 330px
        height: 240px
        top: 450px
        left: -220px
        z-index: 10
        transform: rotate(10deg)
        filter: blur(5px)

    .coin7
        position: absolute
        width: 200px
        height: 150px
        top: 160px
        left: -90px
        transform: rotate(10deg)
        filter: brightness(60%) blur(4px)
