
@media screen and (min-width: 220px)

    .form-box
        width: 158px
        font-family: "Gilroy Bold"
        min-height: 134px

        display: flex
        flex-direction: column
        justify-content: space-between
        .text-admin
            text-align: center
            font-size: 15px
            color: #FFAF01
    .donate-form-label
        width: 100%
        display: flex
        flex-direction: column
        font-family: "Gilroy Bold"
        font-size: 8px
        line-height: 7.92px
        letter-spacing: 0.04em
        text-align: left
        margin-bottom: 12px
        &:last-of-type
            margin-bottom: 4px

        .donate-form-input
            margin-top: 1px
            height: 28px
            width: 100%
            border-radius: 8px
            border: 1px solid #FFFFFF26
            background: #FFFFFF33
            padding-left: 19px
            padding-right: 19px

            font-size: 8px
            font-weight: 500
            line-height: 7.92px
            text-align: left
            color: rgba(255, 255, 255, 1)
            &:focus
                outline: none
                border: none
            &::-webkit-inner-spin-button
                -webkit-appearance: none

            &::placeholder
                font-size: 8px
                font-weight: 500
                line-height: 7.92px
                text-align: left
                color: rgba(255, 255, 255, 0.5)

    .donate-form-text-result

        font-size: 7px
        font-weight: 500
        line-height: 8.5px
        letter-spacing: 0.02em
        text-align: left
        margin-bottom: 7.69px
        display: flex
        .donate-form-text-result-span
            font-weight: 600
            text-align: center
            color: #FFAF01
            display: flex
            align-items: center
            margin-left: 5px
            .donate-form-coin
                width: 6.17px
                height: 7.08px
                margin-left: 2px

    .donate-form-button-submit
        font-family: "Gilroy Bold"
        border-radius: 8px
        width: 100%
        background: #EFA709
        height: 28px
        font-size: 12px
        font-weight: 800
        line-height: 11.88px
        text-align: center
        color: white
        border: 0px
        &:hover
            cursor: pointer
            box-shadow: 0px 0px 27px 0px rgba(255, 138, 54, 0.5)

            text-shadow: 0px 0px 27.299999237060547px rgba(255, 255, 255, 0.5)
@media screen and (min-width: 480px)
    .form-box
        width: 173px
        min-height: 149px
        .text-admin
            font-size: 18px
    .donate-form-label
        width: 100%
        display: flex
        flex-direction: column
        font-family: "Gilroy Bold"
        font-size: 10px
        font-weight: 800
        line-height: 9.9px
        letter-spacing: 0.04em
        text-align: left
        margin-bottom: 12px
        &:last-of-type
            margin-bottom: 4px

        .donate-form-input
            margin-top: 1px
            height: 31.2px
            width: 100%
            border-radius: 8px
            border: 1px solid #FFFFFF26
            background: #FFFFFF33
            padding-left: 19px
            padding-right: 19px
            font-size: 10px
            font-weight: 500
            line-height: 9.9px
            text-align: left
            color: rgba(255, 255, 255, 1)
            &::-webkit-inner-spin-button
                -webkit-appearance: none

            &::placeholder
                font-size: 10px
                font-weight: 500
                line-height: 9.9px
                text-align: left
                color: rgba(255, 255, 255, 0.5)
    .donate-form-text-result

        font-size: 7.9px
        font-weight: 500
        line-height: 9.72px
        letter-spacing: 0.02em
        text-align: left
        margin-bottom: 7.69px
        display: flex
        .donate-form-text-result-span
            font-weight: 600
            text-align: center
            color: #FFAF01
            display: flex
            align-items: center
            margin-left: 5px
            .donate-form-coin
                width: 6.17px
                height: 7.08px
                margin-left: 2px

    .donate-form-button-submit
        font-family: "Gilroy Bold"
        border-radius: 8px
        width: 100%
        background: #EFA709
        color: white
        border: 0px
        height: 31.2px
        font-size: 14px
        font-weight: 800
        line-height: 13.86px
        text-align: center

@media screen and (min-width: 768px)

    .form-box
        width: 260px
        min-height: 232px
        .text-admin
            font-size: 27px
    .donate-form-label
        width: 100%
        display: flex
        flex-direction: column
        font-family: "Gilroy Bold"
        font-size: 15px
        font-weight: 800
        line-height: 14.85px
        letter-spacing: 0.04em
        text-align: left
        margin-bottom: 13.35px
        &:last-of-type
            margin-bottom: 2px

        .donate-form-input
            margin-top: 1px
            height: 45.92px
            width: 100%
            border-radius: 8px
            border: 1px solid #FFFFFF26
            background: #FFFFFF33
            padding-left: 19px
            padding-right: 19px
            font-size: 14px
            font-weight: 500
            line-height: 13.86px
            text-align: left
            color: rgba(255, 255, 255, 1)
            margin-top: 4.73px
            &::-webkit-inner-spin-button
                -webkit-appearance: none

            &::placeholder
                font-size: 14px
                font-weight: 500
                line-height: 13.86px
                text-align: left
                color: rgba(255, 255, 255, 0.5)
    .donate-form-text-result
        font-size: 12px
        font-weight: 500
        line-height: 14.58px
        letter-spacing: 0.02em
        text-align: left
        margin-bottom: 7.69px
        display: flex
        .donate-form-text-result-span
            font-weight: 600
            text-align: center
            color: #FFAF01
            display: flex
            align-items: center
            margin-left: 5px
            .donate-form-coin
                width: 6.17px
                height: 7.08px
                margin-left: 2px

    .donate-form-button-submit
        font-family: "Gilroy Bold"
        border-radius: 8px
        width: 100%
        background: #EFA709
        height: 45.92px
        color: white
        border: 0px
        margin-top: 17.89px
        //         font-size: 14px
        // font-weight: 800
        // line-height: 13.86px
        // text-align: center
        font-size: 18px
        font-weight: 800
        line-height: 13.86px
        text-align: center
@media screen and (min-width: 1024px)

    .form-box
        width: 348px
        min-height: 281.28px
        .text-admin
            font-size: 33px
    .donate-form-label
        font-size: 20px
        font-weight: 800
        line-height: 19.8px
        letter-spacing: 0.04em
        text-align: left
        margin-bottom: 16.35px
        &:last-of-type
            margin-bottom: 3px

        .donate-form-input
            height: 55.69px
            padding-left: 15px
            padding-right: 15px
            font-size: 20px
            font-weight: 500
            line-height: 19.8px
            margin-top: 6.58px
            &::-webkit-inner-spin-button
                -webkit-appearance: none

            &::placeholder
                font-size: 20px
                font-weight: 500
                line-height: 19.8px
    .donate-form-text-result

        font-size: 16px
        font-weight: 500
        line-height: 19.44px
        letter-spacing: 0.02em

        margin-bottom: 22.69px
        display: flex
        .donate-form-text-result-span

            .donate-form-coin
                width: 14.3px
                height: 13.7px
                margin-left: 2px

    .donate-form-button-submit

        height: 55.69px
        // font-family: Gilroy
        // font-size: 30px
        // font-weight: 800
        // line-height: 29.7px
        font-size: 26px
        font-weight: 800
        line-height: 100%
        text-align: center

        text-align: center
        color: white
        border: 0px
        margin-top: 0px
@media screen and (min-width: 1920px)

    .form-box
        width: 435px
        min-height: 340px
        .text-admin
            font-size: 38px
    .donate-form-label
        width: 100%
        display: flex
        flex-direction: column
        font-family: "Gilroy Bold"
        font-size: 24px
        font-weight: 800
        line-height: 23.76px
        letter-spacing: 0.04em
        text-align: left
        margin-bottom: 20px
        &:last-of-type
            margin-bottom: 4px

        .donate-form-input
            margin-top: 8px
            height: 68px
            width: 100%
            border-radius: 8px
            border: 1px solid #FFFFFF26
            background: #FFFFFF33
            padding-left: 19px
            padding-right: 19px
            font-size: 20px
            font-weight: 500
            line-height: 19.8px
            text-align: left
            color: rgba(255, 255, 255, 1)
            &::-webkit-inner-spin-button
                -webkit-appearance: none

            &::placeholder
                font-size: 20px
                font-weight: 500
                line-height: 19.8px
                text-align: left
                color: rgba(255, 255, 255, 0.5)
    .donate-form-text-result
        font-size: 16px
        font-weight: 500
        line-height: 19.44px
        letter-spacing: 0.02em
        text-align: left
        margin-bottom: 29px
        display: flex
        .donate-form-text-result-span
            font-weight: 600
            text-align: center
            color: #FFAF01
            display: flex
            align-items: center
            margin-left: 5px
            .donate-form-coin
                width: 14.32px
                height: 16.7px
                margin-left: 2px

    .donate-form-button-submit
        font-family: "Gilroy Bold"
        border-radius: 8px
        width: 100%
        background: #EFA709
        height: 68px
        // font-size: 34px
        // font-weight: 800
        // line-height: 33.66px
        // text-align: center
        font-size: 32px
        font-weight: 800
        line-height: 100%
        text-align: center
        color: white
        border: 0px
