@use './fadeShow.sass'

@media screen and (min-width: 220px)

    .socials__item_animation-in-0
        @include fadeShow.fadeInMixin(1s,0s)

    .socials__item_animation-in-1
        @include fadeShow.fadeInMixin(1s,0.6s)

    .socials__item_animation-in-2
        @include fadeShow.fadeInMixin(1s,0.4s)
    .socials__item_animation-in-3
        @include fadeShow.fadeInMixin(1s,0.2s)
    .socials__girl_animation
        @include fadeShow.fadeInUpMixin(2s,0.6s)

    .socials__splash_animation
        @include fadeShow.fadeInMixin(1s, 1.5s)

// @media screen and (min-width: 768px)

//     .socials__item_animation-in-2
//         @include fadeShow.zoomInUpMixin(1s,0.5s)

// @media screen and (min-width: 1024px)

//     .socials__item_animation-in-0

//         @include  fadeShow.fadeLeftInMixin(1s)

//     .socials__item_animation-in-1
//         @include fadeShow.bounceInMixin(1s,1.1s)

//     .socials__item_animation-in-2
//         @include fadeShow.fadeInRightMixin(1s)
//     .socials__item_animation-in-3
//         @include fadeShow.fadeInRightMixin(1s)

//     .socials__item_animation-out-0
//         @include fadeShow.fadeOutLeftMixin(1s)

//     .socials__item_animation-out-1
//         @include fadeShow.bounceOutMixin(1s)

//     .socials__item_animation-out-2
//         @include fadeShow.fadeOutRightMixin(1s)
//     .socials__item_animation-out-3
//         @include fadeShow.fadeOutRightMixin(1s)
