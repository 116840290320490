@import 'assets/animation/animationReferral.sass'

@keyframes rotateBorder
    0%
        background-position: 0% 50%
    50%
        background-position: 120% 100%
    100%
        background-position: 0% 50%

    /* opacity: 1; */

@media screen and (min-width: 220px)

    .box-prize
        display: flex
        background-size: 100% 100%
        // overflow: hidden
        position: relative
        width: 100%
        height: 320px
        align-items: center
        justify-content: space-between
        flex-direction: column
        &::before
            content: ""
            position: absolute
            z-index: 1
            inset: 0
            padding: 1px
            border-radius: 10px
            background-size: 200% 200%
            background-image: linear-gradient(90deg, #CF4166 0%, #9E47AF 17%, #218DEF 34%, #34D7C6 51% , #218DEF 68% ,#9E47AF 84%, #CF4166 100%)
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
            mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
            -webkit-mask-composite: xor
            mask-composite: exclude
            animation: rotateBorder 10s linear infinite
            transform-origin: center
        .prize
            position: relative
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
            height: 100%
            justify-content: flex-start
            .div-info-prize
                display: none
                color: rgba(83, 155, 167, 1)
                font-size: 9px
                line-height: 10.7px
                .text
                    width: 110px
            .title-prize
                font-size: 16px
                font-weight: 800
                line-height: 19.6px
                text-align: left
                display: flex
                align-items: center
                justify-content: center
                margin-top: 6px
                .title-prize-animation
                    margin-left: 3px
                    font-family: "Gilroy Heavy"
                    font-size: 18px
                    font-weight: 900
                    line-height: 22.46px
                    text-align: left
                    background-image: linear-gradient(90deg, #CF4166 0%, #9E47AF 17%, #218DEF 34%, #34D7C6 50%, #CF4166 67%, #9E47AF 84%, #218DEF 100%,)
                    background-size: 200% 200%
                    -webkit-background-clip: text
                    -webkit-text-fill-color: transparent
                    animation: gradientAnimation 10s infinite
            .img-prize
                position: absolute
                width: 340px
                height: 181.5px
                bottom: -23%
                z-index: 1
        .list-prize
            background: rgba(255, 255, 255, 0.1)
            padding: 13px 3px 15px 20px
            position: relative
            width: 100%
            height: 250px
            list-style: inside
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: space-between
            border-radius: 0px 0px 10px 10px

            .one-item
                color: rgba(255, 255, 255, 0.7)
                font-size: 10px
                font-weight: 500
                line-height: 11.77px
                text-align: left

        .div-info
            display: none
            position: absolute
            right: 20px
            top: 10px
            color: rgba(83, 155, 167, 1)
            font-size: 15px
            font-weight: 500
            line-height: 17.66px
            text-align: left
            .text

@media screen and (min-width: 480px)
    .box-prize
        height: 180px
        align-items: flex-start
        flex-direction: row
        &::before
            padding: 2px
            border-radius: 14px

        .prize
            flex: 1

            .div-info-prize
                display: flex
                color: rgba(83, 155, 167, 1)
                font-size: 9px
                line-height: 10.7px
                .text
                    width: 109px
            .title-prize
                font-size: 20px
                line-height: 24.5px
                margin-top: 9px
                .title-prize-animation
                    font-size: 24px
                    line-height: 29.95px

            .img-prize
                width: 280.55px
                height: 148px
                top: 36%

        .list-prize
            padding: 20 0px 20 8px
            width: 163px
            border-radius: 0px 14px 14px 0px
            height: 100%
            .one-item
                font-size: 9px
                line-height: 10.7px

        .div-info
            display: none
            flex: 1
            right: 3px
            font-size: 9px
            line-height: 10.7px
            .text
                width: 137px

@media screen and (min-width: 768px)
    .box-prize
        height: 173px
        &::before
            border-radius: 20px
            padding: 3px
        .prize
            flex: 1
            flex-direction: row
            justify-content: center
            align-items: flex-start
            .div-info-prize
                display: none
            .title-prize
                font-size: 20px
                line-height: 24.5px
                margin-top: 9px
                .title-prize-animation
                    font-size: 24px
                    line-height: 29.95px

            .img-prize
                width: 350.55px
                height: 185px
                top: 17%

        .list-prize
            border-radius: 0px 20px 20px 0px
            padding: 20px 0px 20px 13px
            width: 261px
            .one-item
                font-size: 9px
                line-height: 10.7px

        .div-info
            display: flex
            right: 3px
            font-size: 9px
            line-height: 10.7px
            .text
                width: 137px
@media screen and (min-width: 1024px)

    .box-prize
        height: 209px
        &::before
            border-radius: 24px
            padding: 4px
        .prize
            flex: 1
            .title-prize
                font-size: 26px
                line-height: 31.85px
                margin-top: 11px
                .title-prize-animation
                    font-size: 30px
                    line-height: 37.44px

            .img-prize
                position: absolute
                width: 490px
                height: 259px
                top: 5%
        .list-prize
            border-radius: 0px 24px 24px 0px
            padding: 20px 0px 20px 11px
            width: 388px
            .one-item
                font-size: 12px
                line-height: 15.3px

        .div-info
            right: 3px
            font-size: 11px
            line-height: 12.95px

            .text
                width: 141px
@media screen and (min-width: 1920px)
    .box-prize
        position: relative
        width: 99%
        height: 99%
        border-radius: 16px
        display: flex
        background-size: 100% 100%
        // overflow: hidden
        position: relative
        width: 100%
        height: 313px
        align-items: flex-start
        justify-content: space-between
        &::before
            border-radius: 36px
            padding: 6px
        .prize
            flex: 1
            .title-prize
                font-family: Gilroy
                font-size: 36px
                font-weight: 800
                line-height: 44.1px
                text-align: left
                display: flex
                align-items: center
                justify-content: center
                margin-top: 30px
                .title-prize-animation
                    margin-left: 7px
                    font-family: "Gilroy Heavy"
                    font-size: 48px
                    font-weight: 900
                    line-height: 59.9px
                    text-align: left
                    background-image: linear-gradient(90deg, #CF4166 0%, #9E47AF 17%, #218DEF 34%, #34D7C6 50%, #CF4166 67%, #9E47AF 84%, #218DEF 100%,)
                    background-size: 200% 200%
                    -webkit-background-clip: text
                    -webkit-text-fill-color: transparent
                    animation: gradientAnimation 10s infinite
            .img-prize
                position: absolute
                width: 700.55px
                height: 370.76px
                top: 9%

        .list-prize
            border-radius: 0px 36px 36px 0px
            background: rgba(255, 255, 255, 0.1)
            padding: 20px 3px 20px 18px
            position: relative
            right: 0
            top: 50%
            transform: translate(0%,-50%)
            width: 626px
            height: 100%
            list-style: inside
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: space-between
            border-radius: 0 36px 36px 0px
            .one-item
                color: rgba(255, 255, 255, 0.7)
                font-size: 18px
                font-weight: 500
                line-height: 21.19px
                text-align: left
        .div-info
            display: flex
            position: absolute
            right: 20px
            top: 10px
            color: rgba(83, 155, 167, 1)
            font-size: 15px
            font-weight: 500
            line-height: 17.66px
            text-align: left
            .text
                width: 183px
