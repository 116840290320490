.app__main_box {
  font-family: "Gilroy";
  color: var(--white-text);
  overflow: hidden;
  background: linear-gradient(
    179.8deg,
    #010723 36.05%,
    #000b3d 48.04%,
    #010723 69.13%
  );
}

.app-wrapper {
  @media screen and (min-width: 220px) {
    min-height: calc(100vh - 90px);
  }
  @media screen and (min-width: 480px) {
    min-height: calc(100vh - 140px);
  }
  @media screen and (min-width: 768px) {
    min-height: calc(100vh - 233px);
  }
  @media screen and (min-width: 1551px) {
    min-height: calc(100vh - 280px);
  }
  @media screen and (min-width: 1920px) {
    min-height: calc(100vh - 380px);
  }
}

@keyframes visibilitystart {
  0% {
    background-color: rgba(0, 0, 0, 1);

    /* opacity: 0; */
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
    /* opacity: 1; */
  }
}
