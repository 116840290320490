
.donate-wrapper
    width: 100%

    position: relative
    display: flex
    align-items: center
    justify-content: center
    background-size: 100% 100%

    .donate-background-img
        width: 100%
        height: 100vh
        opacity: 0.2
        object-fit: contain
        position: absolute
        top: 0

    .donate-main-box
        margin-top: 180px
        display: flex
        flex-direction: column
        position: relative
        // DELETE AFTER BETA
        // filter: blur(30px)
        .donate-box-title
            height: 50px
            width: 297px
            background: rgba(255, 255, 255, 0.05)
            backdrop-filter: blur(10px)
            border-radius: 16px 16px 0 0
            display: flex
            justify-content: center
            position: relative
            z-index: 1
            .donate-text-title
                color: #FCBA2B
                filter: drop-shadow(0px 0px 49.4px #FCBA2B)
                font-size: 70px
                font-weight: 800
                line-height: 69.3px
                text-align: left
                font-family: "Gilroy Bold"

        .donate-box-info
            height: 500px
            width: 1550px
            background: rgba(255, 255, 255, 0.05)
            backdrop-filter: blur(10px)
            border-radius: 0 16px 16px 16px
            padding: 30px
            display: flex
            justify-content: space-between
            .donate-box-info-img
                width: 358px
                height: 512px
                position: relative
                left: 20px
                bottom: 20px

@media screen and (min-width: 220px)

    .donate-wrapper
        display: flex
        justify-content: center
        align-items: center
        min-height: calc(100vh - 90px)
        width: 100%
        // min-height: 90vh
        position: relative
        display: flex
        align-items: center
        justify-content: center
        .donate-background-img
            width: 300%
            height: 100vh
            opacity: 0.2
            object-fit: contain
            position: absolute
            right: 0
            top: 0
        .donate-main-box
            margin-top: 80px
            display: flex
            flex-direction: column
            position: relative
            .donate-box-title
                height: 35px
                width: 100px
                background: rgba(255, 255, 255, 0.05)
                backdrop-filter: blur(10px)
                border-radius: 16px 16px 0 0
                display: flex
                justify-content: center
                position: relative
                z-index: 1
                .donate-text-title
                    color: #FCBA2B
                    filter: drop-shadow(0px 0px 49.4px #FCBA2B)
                    font-size: 24px
                    font-weight: 800
                    line-height: 23.76px
                    text-align: left
                    font-family: "Gilroy Bold"

            .donate-box-info
                position: relative
                height: 450px
                width: 300px
                background: rgba(255, 255, 255, 0.05)
                backdrop-filter: blur(10px)
                border-radius: 0 16px 16px 16px
                padding: 23px 6.6px 20px 6.6px
                display: flex
                justify-content: flex-end
                flex-wrap: wrap
                align-content: flex-start
                .donate-box-info-img
                    width: 127px
                    height: 178px
                    position: absolute
                    top: 0
                    left: 0

@media screen and (min-width: 480px)

    .donate-wrapper
        min-height: calc(100vh - 140px)
        .donate-background-img
            width: 200%
        .donate-main-box
            margin-top: 120px
            display: flex
            flex-direction: column
            position: relative
            .donate-box-title
                height: 35px
                width: 100px
                background: rgba(255, 255, 255, 0.05)
                backdrop-filter: blur(10px)
                border-radius: 16px 16px 0 0
                display: flex
                justify-content: center
                position: relative
                z-index: 1
                .donate-text-title
                    color: #FCBA2B
                    filter: drop-shadow(0px 0px 49.4px #FCBA2B)
                    font-size: 24px
                    font-weight: 800
                    line-height: 23.76px
                    text-align: left
                    font-family: "Gilroy Bold"

            .donate-box-info
                position: relative
                height: 380px
                width: 427px
                background: rgba(255, 255, 255, 0.05)
                backdrop-filter: blur(10px)
                border-radius: 0 16px 16px 16px
                padding: 35px 6.6px 20px 6.6px
                display: flex
                justify-content: center
                flex-wrap: wrap
                align-content: flex-start
                .donate-box-info-img
                    width: 156px
                    height: 209px
                    position: absolute
                    top: 0
                    left: 40px
                .donate-box-other
                    margin-left: 150px

@media screen and (min-width: 768px)
    .donate-wrapper
        min-height: calc(100vh - 233px)
        align-items: center
        .donate-background-img
            width: 150%
        .donate-main-box
            margin-top: 120px
            display: flex
            flex-direction: column
            position: relative
            .donate-box-title
                height: 35px
                width: 100px
                background: rgba(255, 255, 255, 0.05)
                backdrop-filter: blur(10px)
                border-radius: 16px 16px 0 0
                display: flex
                justify-content: center
                position: relative
                z-index: 1
                .donate-text-title
                    color: #FCBA2B
                    filter: drop-shadow(0px 0px 49.4px #FCBA2B)
                    font-size: 24px
                    font-weight: 800
                    line-height: 23.76px
                    text-align: left
                    font-family: "Gilroy Bold"

            .donate-box-info
                position: relative
                height: 461px
                width: 696px
                background: rgba(255, 255, 255, 0.05)
                backdrop-filter: blur(10px)
                border-radius: 0 16px 16px 16px
                padding: 35px 6.6px 20px 6.6px
                display: flex
                justify-content: flex-end
                flex-wrap: wrap
                align-content: flex-start
                align-items: center
                .donate-box-info-img
                    width: 197px
                    height: 275px
                    position: absolute
                    top: 25px
                    left: 5px
                .donate-box-other
                    margin-left: 0px

@media screen and (min-width:1024px)
    .donate-wrapper
        min-height: calc(100vh - 280px)
        .donate-background-img
        .donate-main-box
            margin-top: 10px
            display: flex
            flex-direction: column
            position: relative
            margin-top: 150px
            .donate-box-title
                height: 40px
                width: 160px
                .donate-text-title
                    color: #FCBA2B
                    filter: drop-shadow(0px 0px 49.4px #FCBA2B)
                    font-size: 46px
                    font-weight: 800
                    line-height: 45.54px
                    text-align: left
                    font-family: "Gilroy Bold"

            .donate-box-info
                height: 540px
                width: 932px
                .donate-box-info-img
                    width: 220px
                    height: 332px
                    position: absolute
                    top: 25px
                    left: 35px

@media screen and (min-width:1920px)

    .donate-wrapper
        min-height: calc(100vh - 380px)
        .donate-background-img
            width: 90%
        .donate-main-box
            margin-top: 180px
            display: flex
            flex-direction: column
            position: relative
            .donate-box-title
                height: 50px
                width: 297px
                background: rgba(255, 255, 255, 0.05)
                backdrop-filter: blur(10px)
                border-radius: 16px 16px 0 0
                display: flex
                justify-content: center
                position: relative
                z-index: 1
                .donate-text-title
                    color: #FCBA2B
                    filter: drop-shadow(0px 0px 49.4px #FCBA2B)
                    font-size: 70px
                    font-weight: 800
                    line-height: 69.3px
                    text-align: left
                    font-family: "Gilroy Bold"

            .donate-box-info
                height: 500px
                width: 1550px
                backdrop-filter: blur(10px)
                padding: 30px
                justify-content: space-between
                align-items: flex-start
                .donate-box-info-img
                    width: 358px
                    height: 512px
                    position: relative
                    left: 20px
                    top: -19px
