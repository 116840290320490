@mixin video_icon_arrow($width , $height)
    width: $width
    height: $height

@media screen and (min-width: 220px)

    .video__box_gallery
        display: flex
        align-items: center
        position: relative
        padding-left: 24px
        padding-right: 24px
        .video__box_img_gallery
            position: relative
            display: flex
            justify-content: center
            align-items: flex-end
            margin: 0 7px

            .video__img_gallery
                width: 100%
                height: 157%
                border-radius: 16px
                &.video__mobile
                    width: 100%
                    height: 80vh
                    object-fit: contain

            .video__exit
                background: transparent
                border: 0px
                font-weight: 800
                position: absolute
                right: 10px
                top: 10px
                color: white
                cursor: pointer
                font-size: 12px
                &:hover
                    color: var(--main-color)
        .video_img_box_right_arrow
            cursor: pointer
            .video__icon
                fill: var(--white-text)
                @include video_icon_arrow(8px, 15px)
            &:hover .video__icon
                fill: var(--main-color)
        .video_img_box_left_arrow
            cursor: pointer
            display: flex
            justify-content: flex-end
            fill: var(--white-text)
            .video__icon
                @include video_icon_arrow(8px, 15px)
            &:hover .video__icon
                fill: var(--main-color)

@media screen and (min-width: 480px)
    .video__box_gallery
        padding-left: 44px
        padding-right: 44px
        .video__box_img_gallery
            margin: 0 9px
            .video__img_gallery
            .video__exit
                font-size: 15px
        .video_img_box_right_arrow
            .video__icon
                @include video_icon_arrow(11px ,19px)
        .video_img_box_left_arrow
            .video__icon
                @include video_icon_arrow(11px ,19px)
@media screen and (min-width: 768px)
    .video__box_gallery
        padding-left: 80px
        padding-right: 80px
        .video__box_img_gallery
            margin: 0 14px
            .video__img_gallery
            .video__exit
                font-size: 17px
        .video_img_box_right_arrow
            .video__icon
                @include video_icon_arrow(17px ,28px)
        .video_img_box_left_arrow
            .video__icon
                @include video_icon_arrow(17px ,28px)
@media screen and (min-width: 1024px)
    .video__box_gallery
        padding-left: 160px
        padding-right: 160px
        .video__box_img_gallery
            margin: 0 17px
            .video__img_gallery
                max-width: 918px
                max-height: 581px
            .video__exit
                font-size: 19px
        .video_img_box_right_arrow
            .video__icon
                @include video_icon_arrow(19px ,33px)
        .video_img_box_left_arrow
            .video__icon
                @include video_icon_arrow(19px ,33px)
@media screen and (min-width: 1920px)
    .video__box_gallery
        display: flex
        align-items: center
        position: relative
        .video__box_img_gallery
            .video__img_gallery
                width: 1010px
                height: 639px
            .video__exit
                background: transparent
                border: 0px
                font-weight: 800
                position: absolute
                right: 16px
                top: 16px
                color: white
                cursor: pointer
                font-size: 24px
        .video_img_box_right_arrow
            .video__icon
                @include video_icon_arrow(31px ,53px)
        .video_img_box_left_arrow
            .video__icon
                @include video_icon_arrow(31px ,53px)
