#contacts__block_wrapper
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    display: flex
    margin: 0 auto
    background-size: cover
    background-position: right
    background-repeat: no-repeat
    background-image: url("../../assets/Images/policyback.png")
    box-shadow: inset 10px -30px 20px 20px #010723 , 0px 20px 20px 20px #010723
    animation: visibilitystart 0.5s forwards
    // height: 100vh

    .contacts__content_wrapper
        max-width: 1504px
        margin: auto 0
        width: 80%
        margin-top: 200px

        .contacts__content_title
            height: 100px
            background: rgba(255, 255, 255, 0.05)
            border-radius: 25px 25px 0px 0px
            margin-right: auto
            display: flex
            flex-direction: column
            align-self: center
            justify-content: center
            width: max-content
            padding: 0% 1.5%

            .contacts__title
                font-family: Gilroy Bold
                font-size: 70px
                font-weight: 800
                line-height: 69.3px
                text-align: center
                text-shadow: 0px 0px 8px white
                width: max-content

        .contacts__content_block--wrapper
            max-width: 1504px
            background: rgba(255, 255, 255, 0.05)
            min-height: 500px
            border-radius: 0 25px 35px 25px
            display: flex
            justify-content: center
            position: relative
            align-items: end
            height: 495px
            .contacts__content_block
                width: 50%
                height: 100%
                display: flex
                flex-direction: column
                gap: 40px
                position: relative
                padding-left: 28px
                margin: auto 0
                margin-right: auto
                .contacts__description
                    top: 25px
                    position: relative
                    font-family: Gilroy
                    font-size: 20px
                    font-weight: 500
                    line-height: 22.2px
                    color: rgba(255, 255, 255, 0.5)

                .contacts__links_wrapper
                    font-size: 50px
                    display: flex
                    align-items: center
                    gap: 20px
                    margin-bottom: 10px
                    margin: auto 0
                    width: max-content

                    .contacts__links_list
                        gap: 20px
                        display: flex,
                        flex-direction: column
                        position: relative
                        bottom: 30px
                        .contacts__list_wrapper
                            line-height: 30px

                        .title
                            font-family: Gilroy
                            font-weight: 600
                            line-height: 49.5px
                            color: rgba(255, 255, 255, 1)
                            text-shadow: 0px 0px 15px white
                            margin-left: -20px
                        .link
                            font-family: Gilroy
                            font-size: 24px
                            font-weight: 500
                            line-height: 26.64px
                            color: rgba(255, 138, 54, 1)
                            display: flex
                            align-items: center
                            gap: 9px

                    .contacts__list
                        display: grid
                        width: max-content
                        grid-template-columns: 1fr 1fr
                        flex-wrap: wrap
                        gap: 16px 16px
                        height: max-content
                        .socials__item
                            opacity: 1
                            position: relative
                            font-size: 33px
                            font-weight: 700
                            line-height: 16px
                            text-align: center
                            width: 210px
                            height: 168px
                            display: flex
                            flex-direction: column
                            align-items: center
                            border-bottom: 6px solid rgba(255, 255, 255, 0.15)
                            border-radius: 0px 0px 8px 8px
                            padding-bottom: 5px
                            justify-content: center
                            gap: 10px
                            .socials__list_icon
                                width: 94px
                                height: 90px
                                margin-bottom: 5px
                            &:hover
                                border-bottom: 6px solid var(--main-color)
                                color: var(--main-color)
                            &:hover .socials__item_gradient
                                transition: height 0.5s 0.5s
                                background: var(--socials-item-active-gradient)
                                animation: 0.7s gradientHeight ease
                            .socials__item_gradient
                                position: absolute
                                left: 0
                                bottom: 0
                                order-radius: 0px 0px 8px 8px
                                width: 100%
                                height: 100%
                                background: var(--socials-item-gradient)

            .contacts__photo_block
                width: 565px
                height: 515px
                background-image: url("../../assets/Images/gtaback.png")
                background-size: cover
                background-position: right
                background-repeat: no-repeat
                z-index: 3
                position: absolute
                bottom: 0
                right: 0
                pointer-events: none
@media screen and (max-width: 1850px)
    #contacts__block_wrapper
        min-height: 1300px
        .contacts__content_wrapper
            flex-direction: column
            max-width: 1000px

            .contacts__content_title
                height: 65px
                border-radius: 20px 20px 0px 0px

                .contacts__title
                    font-size: 45px

            .contacts__content_block--wrapper
                min-height: 965px
                margin: 0 auto
                flex-direction: column

                .contacts__content_block
                    width: 64%
                    width: 918px
                    margin: auto
                    position: relative
                    top: 30px
                    margin: 0 auto
                    padding: 0

                    .contacts__description
                        top: 0
                        text-align: center
                        width: 80%
                        margin: 0 auto

                    .contacts__links_wrapper
                        margin: 0

@media screen and (max-width: 1185px)
    #contacts__block_wrapper
        min-height: 1550px
        .contacts__content_wrapper
            min-height: 1300px

            .contacts__content_title
                height: 45px
                border-radius: 10px 10px 0px 0px

                .contacts__title
                    font-size: 35px

            .contacts__content_block--wrapper
                border-radius: 0px 10px 10px 10px
                min-height: 1190px

                .contacts__content_block
                    width: 100%
                    margin: auto
                    position: relative
                    top: 30px
                    margin: 0 auto
                    padding: 0

                    .contacts__description
                        top: 0
                        font-size: 15px
                        text-align: center

                    .contacts__links_wrapper
                        margin: 0
                        font-size: 30px
                        flex-direction: column
                        width: max-content
                        margin: 0 auto
                        .contacts__links_list
                            .link
                                font-size: 18px

                        .contacts__list
                            .socials__item
                                font-size: 23px
                                width: 170px
                                height: 150px
                                .socials__list_icon
                                    width: 60px
                                    height: 60px

                .contacts__photo_block

@media screen and (max-width: 720px)
    #contacts__block_wrapper
        min-height: 1200px
        .contacts__content_wrapper
            min-height: 0px

            .contacts__content_title
                height: 35px
                border-radius: 10px 10px 0px 0px

                .contacts__title
                    font-size: 27px

            .contacts__content_block--wrapper
                min-height: 930px
                border-radius: 0px 10px 10px 10px

                .contacts__content_block

                    .contacts__description

                    .contacts__links_wrapper
                        font-size: 20px
                        line-height: 10px
                        .contacts__links_list
                            .title
                                line-height: 25.5px
                                margin-left: -5px
                                font-size: 25px
                            .link
                                font-size: 16px

                        .contacts__list
                            .socials__item
                                font-size: 18px
                                width: 129px
                                height: 100px
                                .socials__list_icon
                                    width: 40px
                                    height: 40px

                .contacts__photo_block
                    width: 372px
                    height: 339px

@media screen and (max-width: 470px)
    #contacts__block_wrapper
        min-height: 500px
        .contacts__content_wrapper
            min-height: 0px

            .contacts__content_title
                height: 25px
                border-radius: 10px 10px 0px 0px

                .contacts__title
                    font-size: 20px

            .contacts__content_block--wrapper
                min-height: 670px
                border-radius: 0px 10px 10px 10px

                .contacts__content_block

                    .contacts__description
                        font-size: 10px
                        line-height: 12px

                    .contacts__links_wrapper
                        font-size: 15px
                        line-height: 10px

                        .contacts__links_list
                            .title
                                line-height: 10.5px
                                font-size: 15px
                                margin-left: -7px
                            .link
                                font-size: 10px

                        .contacts__list
                            .socials__item
                                font-size: 14px
                                width: 83px
                                height: 74px
                                .socials__list_icon
                                    width: 24px
                                    height: 24px
                                    margin: 0

                .contacts__photo_block
                    width: 252px
                    height: 228px
