.ref_list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  z-index: 999999999;
  padding: 0px 21px;  
  padding-bottom: 10px;
}

.referrals__reward_wrapper {
  display: flex;
  overflow-y: hidden;
  width: 100%;
  gap: 21px;
  height: 195px;
  padding-top: 30px;

  &::-webkit-scrollbar {
    max-height: 5px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(91, 91, 91, 1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      270deg,
      #16b19e 0%,
      #3680e7 32.34%,
      #9647bb 66.79%,
      #d04165 100%
    );

    border-radius: 10px;
  }

  .referrals__reward_list,
  .referrals__reward_list-no {
    width: 162.97px;
    height: 150px;
    min-width: 162.97px;
    border-radius: 10px;
    padding-top: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // transform: rotate(270deg);

    .referrals__spray_svg,
    .referrals__spray_svg-no {
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 102px;
      height: 70px;
      position: absolute;
      top: -25px;
      right: -20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .referrals__coinsSvg {
      width: 100%;
      height: 126px;
    }
  }
}

.referrals__spray_svg {
  background-image: url("./images/spray.png");
}

.referrals__spray_svg-no {
  background-image: url("./images/sprayno.png");
}

.referrals__reward_list-no {
  opacity: 0.6;
  background: linear-gradient(
    180deg,
    rgba(116, 116, 116, 0) 0%,
    rgba(146, 146, 146, 0.4) 100%
  );
  border-bottom: 8px solid rgba(128, 128, 128, 1);
}

.ref_keys_title {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
  color: #bdbdbd;
  position: relative;
  bottom: 8px;
}

.ref_manwrap {
  position: relative;
  display: flex;
  align-items: center;
}

.referrals_mansvg {
  width: 45px;
  height: 50px;
  position: absolute;
  left: -29px;
  opacity: 0.7;
  top: -37px;
}

.referrals_number {
  font-family: 'Gilroy Heavy';
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.6px;
  left: 7px;
  opacity: 0.7;
  position: absolute;
  line-height: 34.94px;
  color: rgb(255 255 255);
  top: -12px;
  min-width: 25px;
}

.ref_sum_title {
  font-family: Gilroy;
  font-size: 30px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  position: absolute;
  left: 11px;
  top: 9px;
  color: rgb(68, 255, 255);
  rotate: 346deg;
}

/// colors
.red {
  background: linear-gradient(
    180deg,
    rgba(237, 30, 80, 0) 0%,
    rgba(237, 30, 80, 0.41) 100%
  );
  border-bottom: 8px solid rgba(237, 30, 80, 1);
}

.pink {
  background: linear-gradient(
    180deg,
    rgba(215, 52, 179, 0) 0%,
    rgba(215, 52, 179, 0.41) 100%
  );
  border-bottom: 8px solid rgba(215, 52, 179, 1);
}

.smallpink {
  background: linear-gradient(
    180deg,
    rgba(167, 58, 194, 0) 0%,
    rgba(167, 58, 194, 0.41) 100%
  );

  border-bottom: 8px solid rgba(167, 58, 194, 1);
}

.blue {
  background: linear-gradient(
    180deg,
    rgba(25, 127, 200, 0) 0%,
    rgba(25, 127, 200, 0.41) 100%
  );

  border-bottom: 8px solid rgba(25, 127, 200, 1);
}

.green {
  background: linear-gradient(
    180deg,
    rgba(29, 171, 155, 0) 0%,
    rgba(29, 171, 155, 0.41) 100%
  );

  border-bottom: 8px solid rgba(29, 171, 155, 1);
}

.gold {
  background: linear-gradient(
    180deg,
    rgba(29, 171, 155, 0) 0%,
    rgba(208, 211, 23, 0.41) 100%
  );

  border-bottom: 8px solid rgb(255, 251, 35);
}

@media screen and (max-width: 1024px) {
  .referrals__reward_wrapper {
    height: 150px;
    gap: 17px;
    .referrals__reward_list,
    .referrals__reward_list-no {
      min-width: 118.56px;
      height: 106.73px;
      border-radius: 8px;

      .referrals__spray_svg,
      .referrals__spray_svg-no {
        width: 80px;
        height: 60px;
      }

      .referrals__coinsSvg {
        width: 80%;
        height: 100px;
        margin: auto;
      }
    }
  }

  .referrals_mansvg {
    width: 40px;
    height: 44px;
    left: -26px;
    top: -35px;
  }

  .referrals_number {
          left: 7px;
      letter-spacing: 0.8px;
    font-size: 18px;
    top: -14px;
  }

  .ref_sum_title {
    font-size: 23px;
    left: 30px;
  }

  .ref_keys_title {
    font-size: 11px;
    bottom: 4px;
  }

  .red,
  .pink,
  .smallpink,
  .blue,
  .green,
  .gold {
    border-bottom-width: 5px;
  }
}

@media screen and (max-width: 480px) {
  .referrals__reward_wrapper {
    gap: 13px;
    height: 130px;
    &::-webkit-scrollbar {
      max-height: 3px;
      border-radius: 5px;
    }

    .referrals__reward_list,
    .referrals__reward_list-no {
      height: 90px;
      min-width: 84px;

      .referrals__spray_svg,
      .referrals__spray_svg-no {
        width: 60px;
        height: 45px;
        right: -7px;
        top: -14px;
      }

      .referrals__coinsSvg {
        width: 60%;
        height: 50px;
        margin-top: 12px;
      }
    }
  }

  .referrals_mansvg {
    width: 30px;
    height: 33px;
    left: -19px;
    top: -29px;
  }

  .referrals_number {
      left: 6px;
    font-size: 14px;
    top: -17px;
  }

  .ref_sum_title {
    font-size: 16px;
    left: 12px;
    top: 12px;
  }

  .ref_keys_title {
    font-size: 12px;
    bottom: 4px;
  }

  .red,
  .pink,
  .smallpink,
  .blue,
  .green,
  .gold {
    border-bottom-width: 6px;
  }
}
