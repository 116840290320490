@mixin descriptiontext
    font-family: Gilroy
    font-size: 24px
    font-weight: 500
    line-height: 26.64px
    color: rgba(255, 255, 255, 0.5)
    @media screen and (max-width: 1550px)
        font-size: 20px
    @media screen and (max-width: 1024px)
        font-size: 15px
        line-height: 15.64px
    @media screen and (max-width: 480px)
        font-size: 10px
        line-height: 12.64px

#policy__block_wrapper
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    display: flex
    margin: 0 auto
    background-size: cover
    background-position: right
    background-repeat: no-repeat
    background-image: url("../../assets/Images/policyback.png")
    box-shadow: inset 10px -30px 20px 20px #010723 , 0px 20px 20px 20px #010723
    animation: visibilitystart 0.5s forwards
    // height: 100vh
    min-height: 1000px

    .policy__content_wrapper
        max-width: 1504px
        margin: auto 0
        margin-top: 200px

        .policy__content_title
            width: max-content
            padding-left: 27px
            height: 100px
            background: rgba(255, 255, 255, 0.05)
            border-radius: 25px 25px 0px 0px
            margin-right: auto
            display: flex
            flex-direction: column
            align-self: center
            justify-content: center
            padding: 0 2%
            padding-left: 28px

            .policy__title
                font-family: Gilroy Bold
                font-size: 70px
                font-weight: 800
                line-height: 69.3px
                text-align: center
                text-shadow: 0px 0px 8px white
                width: max-content

        .policy__content_block--wrapper
            max-width: 1504px
            background: rgba(255, 255, 255, 0.05)
            height: 612px
            border-radius: 0 25px 25px 25px
            display: flex
            justify-content: center
            position: relative
            align-items: center
            flex-direction: row
            .policy__content_block
                width: 98.5%
                height: 95%
                display: flex
                flex-direction: column
                gap: 40px
                overflow-y: scroll
                position: relative
                margin-right: auto
                padding: 10px 29px 0px 28px

                &::-webkit-scrollbar
                    width: 3px
                    margin-top: 10px
                    position: absolute
                    right: 10px

                &::-webkit-scrollbar-track
                    background-color: rgba(4, 9, 34, 1)
                    margin-right: 5px

                &::-webkit-scrollbar-thumb
                    background-color: rgba(255, 255, 255, 0.45)
                    margin-right: 5px

                .policy__description
                    @include descriptiontext

                .policy__block_wrapper

                    .policy__block_title
                        margin-bottom: 20px
                        font-family: Gilroy
                        font-size: 50px
                        font-weight: 600
                        line-height: 49.5px
                        text-shadow: 0px 0px 20px #e48d2b

                    .policy__block_description
                        @include descriptiontext

@media screen and (max-width: 1550px)
    #policy__block_wrapper
        min-height: 800px
        .policy__content_wrapper
            .policy__content_title
                height: 65px
                border-radius: 20px 20px 0px 0px
                margin-left: 5%
                width: max-content
                padding-right: 2.5%

                .policy__title
                    font-size: 45px
            .policy__content_block--wrapper
                width: 90%
                margin: 0 auto
                border-radius: 0px 20px 20px 20px
                .policy__content_block
                    .policy__block_wrapper
                        .policy__block_title
                            line-height: 39.5px
                            margin-bottom: 10px
                            font-size: 35px

@media screen and (max-width: 1024px)
    #policy__block_wrapper
        .policy__content_wrapper
            .policy__content_title
                height: 40px
                padding-left: 20px
                border-radius: 10px 10px 0px 0px
                .policy__title
                    font-size: 20px
            .policy__content_block--wrapper
                border-radius: 0px 10px 10px 10px
                .policy__content_block
                    padding: 10px 29px 0px 20px
                    .policy__block_wrapper
                        .policy__block_title
                            line-height: 29.5px
                            margin-bottom: 0px
                            font-size: 20px

@media screen and (max-width: 480px)
    #policy__block_wrapper
        .policy__content_wrapper
            .policy__content_title
                height: 30px
                padding-left: 15px
                border-radius: 10px 10px 0px 0px
                .policy__title
                    font-size: 12px
            .policy__content_block--wrapper
                border-radius: 0px 10px 10px 10px
                .policy__content_block
                    padding: 10px 29px 0px 15px
                    .policy__block_wrapper
                        .policy__block_title
                            margin-bottom: 0px
                            font-size: 10px
                            line-height: 19.5px
