@use './fadeShow.sass'

@media screen and (min-width: 220px)

    .howtoplay__item_animation-in-0

        @include  fadeShow.fadeLeftInNoOpacityMixin(1s)

    .howtoplay__item_animation-in-1
        @include fadeShow.fadeInRightNoOpacityMixin(1s)

    .howtoplay__item_animation-in-2
        @include fadeShow.zoomInUpMixin(1s,0.1s)

@media screen and (min-width: 768px)

    .howtoplay__item_animation-in-2
        @include fadeShow.zoomInUpMixin(1s,0.5s)

@media screen and (min-width: 1024px)

    .howtoplay__item_animation-in-0

        @include  fadeShow.fadeLeftInNoOpacityMixin(1s)

    .howtoplay__item_animation-in-1
        @include fadeShow.fadeInUpNoOpacityMixin(1s,1.1s)

    .howtoplay__item_animation-in-2
        @include fadeShow.fadeInRightNoOpacityMixin(1s)
