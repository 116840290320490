@media screen and (min-width: 220px)
    .box-info
        padding: 0px
        border-radius: 36px
        width: 100%
        height: max-content
        display: flex
        justify-content: space-between
        position: relative
        flex-direction: column

        .info-box-text
            width: 100%
            .info-title
                font-family: 'Gilroy Bold'
                font-size: 18px
                font-weight: 800
                line-height: 22.05px
                text-align: center
                margin-top: 11px

            .info-text
                font-family: Gilroy
                font-size: 10px
                font-weight: 500
                line-height: 11.77px
                width: 73%
                text-align: center
                margin: 0 auto
                margin-top: 6px
                opacity: 0.8

        .list-steps
            display: flex
            width: 378px
            margin-top: 31px
            width: 100%
            align-items: center
            flex-wrap: wrap
            justify-content: center
            gap: 15px
            .item-step
                display: flex
                flex-direction: column
                width: 118px
                height: 165px
                .box-number
                    display: flex
                    justify-content: flex-end
                    height: 29px
                    position: relative
                    .number
                        top: -8px
                        font-size: 42px
                        font-weight: 900
                        line-height: 41.58px
                        letter-spacing: -0.02em
                        position: absolute
                        left: 0
                        top: -5px
                        font-family: 'Gilroy Heavy'
                        line-height: 100%
                        background-image: linear-gradient(90deg, #CF4166 0%, #9E47AF 17%, #218DEF 34%, #34D7C6 50%, #CF4166 67%, #9E47AF 84%, #218DEF 100%,)
                        background-size: 200% 200%
                        -webkit-background-clip: text
                        -webkit-text-fill-color: transparent
                        animation: gradientAnimation 6s infinite
                    .background-blur
                        width: 65px
                        backdrop: rgba(255,255,255, 0.05)
                        border: 1px solid rgba(255, 255, 255, 0.15)
                        border-bottom: 0px solid
                        border-radius: 6px 6px 0px 0px
                        backdrop-filter: blur(10px)
                        display: flex
                        justify-content: center
                        align-items: center
                        .img-step
                            z-index: 3
                            object-fit: contain
                            width: 49.65px
                            height: 51.22px

                .box-text
                    flex: 1
                    with: 100%
                    border-radius: 6px 0px 6px 6px
                    border: 1px solid rgba(255, 255, 255, 0.15)
                    backdrop-filter: blur(10px)
                    padding: 9px 3px 9px 8px
                    border-top: 0px
                    z-index: -1
                    .title
                        text-align: left
                        margin-bottom: 14px
                        font-size: 12px
                        font-weight: 700
                        line-height: 12.6px
                        letter-spacing: -0.02em
                    .text
                        font-family: Gilroy
                        font-size: 10px
                        font-weight: 400
                        line-height: 11.6px
                        text-align: left
                        position: relative
                        color: rgba(255, 255, 255, 0.5)

@media screen and (min-width: 480px)
    .box-info
        padding: 0
        flex-direction: column
        align-items: center

        .info-box-text
            text-align: center

            .info-title
                font-size: 18px
                font-weight: 800
                line-height: 22.05px
                text-align: center
                margin-top: 11px

            .info-text
                font-size: 10px
                font-weight: 500
                line-height: 11.77px
                width: 73%
                text-align: center
                margin: 0 auto
                margin-top: 6px

        .list-steps
            margin-top: 31px
            width: 378px
            flex-wrap: nowrap
            .item-step
                width: 118px
                height: 160px
                .box-number
                    height: 29px
                    .number
                        top: -8px
                        font-size: 42px
                        font-weight: 900
                        line-height: 41.58px
                        letter-spacing: -0.02em

                    .background-blur
                        width: 65px
                        .img-step
                            width: 49.65px
                            height: 51.22px

                .box-text
                    padding: 11px 4px 0px 8px

                    .title
                        font-size: 12px
                        font-weight: 700
                        line-height: 12.6px
                        letter-spacing: -0.02em

                    .text
                        font-size: 10px
                        font-weight: 400
                        line-height: 11.6px
                        bottom: 5px

@media screen and (min-width: 768px)
    .box-info
        padding: 20px 9px 0px 70px
        justify-content: space-between
        flex-direction: row

        .info-box-text

            .info-title
                font-size: 16px
                font-weight: 800
                line-height: 19.6px
                text-align: start

            .info-text
                font-size: 10px
                font-weight: 500
                line-height: 11.77px
                width: 83%
                margin-top: 8px
                text-align: start
                margin: 0

        .list-steps
            margin-top: 10px
            width: 925px
            .item-step
                width: 125px
                height: 160px
                .box-number
                    height: 29px
                    .number
                        top: -8px
                        font-size: 42px
                        font-weight: 900
                        line-height: 41.58px
                        letter-spacing: -0.02em

                    .background-blur
                        width: 73px
                        .img-step
                            width: 49.65px
                            height: 51.22px

                .box-text
                    padding: 11px 8px 0px 9px

                    .title
                        font-size: 12px
                        font-weight: 700
                        line-height: 12.6px
                        letter-spacing: -0.02em

                    .text
                        font-size: 10px
                        font-weight: 400
                        line-height: 11.6px
                        bottom: 5px

@media screen and (min-width: 1024px)
    .box-info
        padding: 21px 18px 0px 72px

        .info-box-text

            .info-title
                font-size: 26px
                line-height: 31.85px

            .info-text
                font-size: 16px
                font-weight: 400
                line-height: 18.56px
                position: relative
                top: 13px
                width: 95%
                margin-top: 0px

        .list-steps
            margin-top: 44px
            width: 743px
            .item-step
                width: 157px
                height: 190px
                .box-number
                    height: 37px
                    .number
                        font-size: 54px
                        line-height: 53.46px
                        top: -12px
                    .background-blur
                        width: 93px
                        .img-step
                            width: 63px
                            height: 65px

                .box-text
                    padding: 13px 8px 0px 9px

                    .title
                        font-size: 16px
                        line-height: 16.8px

                    .text
                        font-size: 12px
                        line-height: 13.92px
                        bottom: 0

@media screen and (min-width: 1920px)
    .box-info
        padding: 35px 33px 0px 119px
        border-radius: 36px
        width: 100%
        height: 290px
        display: flex
        justify-content: space-between
        position: relative

        .info-box-text
            width: 478px
            height: 100%
            .info-title
                font-family: 'Gilroy Bold'
                font-size: 42px
                font-weight: 800
                line-height: 56.35px
                text-align: left
                margin-top: 10px

            .info-text
                font-family: Gilroy
                font-size: 18px
                font-weight: 500
                line-height: 21.19px
                text-align: left
                color: rgba(255, 255, 255, 0.5)
        .list-steps
            margin-top: 0px
            display: flex
            width: 817px
            justify-content: space-between
            margin-top: 0
            height: max-content
            .item-step
                display: flex
                flex-direction: column
                width: 242.5px
                height: 270px
                .box-number
                    display: flex
                    justify-content: flex-end
                    height: 60px
                    position: relative
                    .number
                        position: absolute
                        left: 0
                        top: -17px
                        font-size: 80px
                        font-family: 'Gilroy Heavy'
                        font-weight: 800
                        line-height: 100%
                        background-image: linear-gradient(90deg, #CF4166 0%, #9E47AF 17%, #218DEF 34%, #34D7C6 50%, #CF4166 67%, #9E47AF 84%, #218DEF 100%,)
                        background-size: 200% 200%
                        -webkit-background-clip: text
                        -webkit-text-fill-color: transparent
                        animation: gradientAnimation 6s infinite
                    .background-blur
                        width: 140px
                        backdrop: rgba(255,255,255, 0.05)
                        border: 1px solid rgba(255, 255, 255, 0.15)
                        border-bottom: 0px solid
                        border-radius: 12px 12px 0px 0px
                        backdrop-filter: blur(10px)
                        display: flex
                        justify-content: center
                        align-items: center
                        .img-step
                            object-fit: contain
                            width: 95px
                            height: 110px

                .box-text
                    flex: 1
                    with: 100%
                    border-radius: 12px 0px 12px 12px
                    border: 1px solid rgba(255, 255, 255, 0.15)
                    backdrop-filter: blur(10px)
                    padding: 20px 7.5px 23px 12px
                    border-top: 0px
                    min-height: 189px
                    .title
                        font-size: 24px
                        font-weight: 700
                        line-height: 25.2px
                        letter-spacing: -0.02em
                        text-align: left
                        margin-bottom: 14px
                        padding-right: 10px
                    .text
                        font-family: Gilroy
                        font-size: 16px
                        font-weight: 400
                        line-height: 18.56px
                        text-align: left
                        color: rgba(255, 255, 255, 0.5)
