@media screen and (min-width: 220px)
    .circle_list
        position: absolute
        display: flex
        justify-content: space-between
        width: 60px
        margin-bottom: 11px
        cursor: pointer
        .circle_item_img
            width: 5px
            height: 5px
            border-radius: 50%
            background: rgba(255, 255, 255, 0.3)
            &:hover
                border: 1px solid white
            .circle__item_img_active

                background: white
@media screen and (min-width: 480px)
    .circle_list
        position: absolute
        display: flex
        justify-content: space-between
        width: 70px
        margin-bottom: 11px
        cursor: pointer
        .circle_item_img
            width: 6px
            height: 6px
            border-radius: 50%
            background: rgba(255, 255, 255, 0.3)
            &:hover
                border: 1px solid white
            .circle__item_img_active

                background: white
@media screen and (min-width: 768px)
    .circle_list
        position: absolute
        display: flex
        justify-content: space-between
        width: 90px
        margin-bottom: 11px
        cursor: pointer
        .circle_item_img
            width: 9px
            height: 9px
            border-radius: 50%
            background: rgba(255, 255, 255, 0.3)
            &:hover
                border: 1px solid white
            .circle__item_img_active

                background: white

@media screen and (min-width: 1024px)
    .circle_list
        position: absolute
        display: flex
        justify-content: space-between
        width: 119px
        margin-bottom: 11px
        cursor: pointer
        .circle_item_img
            width: 14px
            height: 14px
            border-radius: 50%
            background: rgba(255, 255, 255, 0.3)
            &:hover
                border: 1px solid white
            .circle__item_img_active

                background: white
