@media screen and (min-width: 220px)
    .donate-box-form
        display: flex
        flex-direction: column
        width: 100%
        margin-top: 21px
        margin-bottom: 12px
        .donate-box-bonus
            display: flex
            flex-direction: column
            .donate-bonus-text
                font-size: 7px
                font-weight: 500
                line-height: 8.5px
                letter-spacing: 0.02em
                text-align: center
                color: rgba(255, 255, 255, 0.5)
                .donate-bonus-list
                    display: flex
                    flex-direction: column

@media screen and (min-width: 480px)

    .donate-box-form
        display: flex
        flex-direction: column
        width: 100%
        margin-top: 21px
        margin-bottom: 12px
        .donate-box-bonus
            display: flex
            flex-direction: column
            .donate-bonus-text
                font-size: 8px
                font-weight: 500
                line-height: 9.72px
                letter-spacing: 0.02em
                text-align: center
                color: rgba(255, 255, 255, 0.5)
                .donate-bonus-list
                    display: flex
                    flex-direction: column

@media screen and (min-width: 768px)
    .donate-box-form
        display: flex
        flex-direction: column
        width: 203px
        margin-top: auto
        margin-bottom: auto
        margin-left: 14.7px
        .donate-box-bonus
            width: 203px
            display: flex
            flex-direction: column

            .donate-bonus-text
                font-size: 12px
                font-weight: 500
                line-height: 14.58px
                letter-spacing: 0.02em
                text-align: center
                color: rgba(255, 255, 255, 0.5)
                .donate-bonus-list
                    display: flex
                    flex-direction: column

@media screen and (min-width:1024px)
    .donate-box-form

        width: 257px

        margin-left: 22.75px
        .donate-box-bonus
            width: 100%

            .donate-bonus-text
                font-size: 15px
                font-weight: 500
                line-height: 18.22px
                letter-spacing: 0.02em
                text-align: center
                color: rgba(255, 255, 255, 0.5)
                .donate-bonus-list
                    display: flex
                    flex-direction: column

@media screen and (min-width:1920px)
    .donate-box-form
        display: flex
        flex-direction: column
        margin-left: 0
        width: 100%
        .donate-box-bonus
            display: flex
            flex-direction: column
            margin-top: 18px
            .donate-bonus-text
                font-size: 16px
                font-weight: 500
                line-height: 19.44px
                letter-spacing: 0.02em
                text-align: center
                color: rgba(255, 255, 255, 0.5)
                .donate-bonus-list
                    display: flex
                    flex-direction: column
