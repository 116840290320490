@mixin header__button_styles
    font-family: Gilroy
    font-size: 20px
    font-weight: 600
    line-height: 23.86px
    text-align: left
    color: rgba(255, 255, 255, 0.5)
    background: none
    border: none
    position: relative
    transition: color 0.5s

@mixin header__underline($backgroundCol , $position , $width, $top)
    position: $position
    top: $top
    height: 2px
    width: $width
    background: $backgroundCol
    transition: width 0.5s, opacity 0.5s, transform 0.5s, background 0.5s
    transform-origin: left
    border-top-left-radius: 5px
    border-bottom-left-radius: 5px

#header__wrapper
    width: 100%
    height: 112px
    z-index: 1000 !important
    background: rgba(14, 23, 37, 0.3)
    display: flex
    align-items: center
    position: absolute
    top: 0
    backdrop-filter: blur(10px)
    justify-content: center

    .header__content
        width: 79%
        max-width: 1504px
        display: flex
        justify-content: space-between
        align-items: center

        .header__logotype
            filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.5))
            width: 66px
            height: 55px
            cursor: pointer

        .header__buttons_list
            display: flex
            list-style: none
            gap: 114px

            .header__buttons
                @include header__button_styles
                cursor: pointer

                &:hover
                    color: rgba(255, 255, 255, 1)

                    .header__btn_underline
                        transform: scaleX(1)
                        width: 100%
                        background: linear-gradient(90deg, #ffffff 30.72%, rgba(255, 255, 255, 0) 100%)

                .header__btn_underline
                    @include header__underline(linear-gradient(90deg, #ffffff6e 30.72%, rgba(255, 255, 255, 0) 100%) , absolute , 0 , 25px)

.header__background_clicker
    width: 100vw
    height: 100vh
    z-index: 99
    top: 0
    left: 0
    position: fixed

.header__burger_wrapper
    display: none
    position: fixed
    right: 0
    z-index: 9999 !important
    right: 44px
    top: 33px

.header__burger_menu
    z-index: 9998 !important
    position: absolute
    display: none
    right: 0
    width: 320px
    height: 100vh
    background: rgba(4, 9, 33, 1)
    animation: slideFromRight 0.2s ease forwards
    z-index: 99

    &.slide-out
        animation: slideToRight 0.2s ease forwards

    .header__burger_active
        width: max-content
        margin: 14px 14px 0 auto

    .header__burger_buttons
        padding-left: 43px
        margin-top: 66px
        display: flex
        flex-direction: column
        gap: 32px

        .header__burger_button
            @include header__button_styles
            cursor: pointer

            .header__burger_underline
                @include header__underline(linear-gradient(90deg,  #ffffff6e 30.72%, rgba(255, 255, 255, 0) 100%) , relative , 100% , 5px)
                transition: background 0.5s

            &:hover
                color: rgba(255, 255, 255, 1)

                .header__burger_underline
                    background: linear-gradient(90deg, #ffffff 30.72%, rgba(255, 255, 255, 0) 100%)

@keyframes slideFromRight
    from
        opacity: 0
        visibility: hidden
        box-shadow: none
    to
        opacity: 1
        visibility: visible
        box-shadow: -48vw 0vw 5vw 100vw rgba(25, 25, 27, 0.63)

@keyframes slideToRight
    from
        opacity: 1
        box-shadow: -48vw 0vw 5vw 100vw rgba(25, 25, 27, 0.63)
        visibility: visible

    to
        opacity: 0
        box-shadow: none
        visibility: hidden

@media screen and (max-width: 1550px)
    #header__wrapper
        height: 85px
        position: absolute
        .header__content
            width: 91%

            .header__logotype
                width: 52px
                height: 43px

            .header__buttons_list
                gap: 73px

                .header__buttons
                    font-size: 18px
                    line-height: 21.47px

@media screen and (max-width: 1024px)
    #header__wrapper
        position: fixed
        .header__content

            .header__logotype
                width: 52px
                height: 43px

            .header__buttons_list
                display: none

    .header__burger_menu
        display: block
        position: fixed

    .header__burger_wrapper
        display: block
        position: fixed

@media screen and (max-width: 480px)
    #header__wrapper
        height: 60px
        padding: 0 32px

        .header__content

            .header__logotype
                width: 38px
                height: 32px

    .header__burger_wrapper
        top: 24px
        right: 32px

@media screen and (max-width: 320px)
    #header__wrapper
    .header__burger_menu
        width: 100%
