@mixin statistic_wrapper($border-radius)
    height: 100%
    width: 49.5%
    background: rgba(255, 255, 255, 0.21)
    border-radius: $border-radius

#homepage__wrapper
    width: 100%
    position: relative
    height: 100vh
    min-height: 700px
    background-size: cover
    background-position: right
    background-repeat: no-repeat
    background-image: url("../../../assets/Images/backgroundliberty.png")
    display: flex
    flex-direction: column
    align-items: center

    .homepage__content
        max-width: 1504px
        height: 100%
        width: 79%
        display: flex
        flex-direction: column

        .homepage__greeting
            display: flex
            margin-top: 223px

            .homepage__greeting_leftside
                display: flex
                flex-direction: column

                .homepage__desc
                    font-family: Gilroy
                    font-size: 50px
                    font-weight: 500
                    line-height: 54px
                    color: rgba(255, 255, 255, 0.7)

                .homepage__title
                    font-family: Gilroy Bold
                    font-size: 90px
                    font-weight: 800
                    line-height: 89.1px
                    color: rgba(255, 255, 255, 1)
                    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.4))

        .homepage__statistic
            margin-top: 72px
            width: 688px
            height: 232px
            display: flex
            justify-content: space-between
            z-index: 1

            .homepage__statistic_left
                @include statistic_wrapper(16px 0px 0px 16px)
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                gap: 16px

                .homepage__statistic_left--title
                    display: flex
                    align-items: center
                    justify-content: space-between
                    width: 100%
                    padding: 0 24px
                    .title
                        font-family: Gilroy
                        font-size: 34px
                        font-weight: 700
                        line-height: 41.11px
                        text-align: left
                        color: rgba(255, 138, 54, 1)

                    .status
                        font-family: Gilroy
                        font-size: 22px
                        font-weight: 400
                        line-height: 25.52px
                        color: rgba(255, 255, 255, 0.5)

                .homepage__stistic_online--status
                    display: flex
                    flex-direction: column
                    align-items: center

                    & > span
                        font-family: Gilroy
                        font-size: 26px
                        font-weight: 500
                        line-height: 30.6px
                        color: rgba(255, 255, 255, 0.5)

                    .homepage__statistic_value--wrapper

                        .online
                            font-family: Gilroy
                            font-size: 60px
                            font-weight: 700
                            line-height: 72.54px
                            color: rgba(255, 255, 255, 1)
                            letter-spacing: 3px

                        .offline
                            font-family: Gilroy
                            font-size: 40px
                            font-weight: 700
                            line-height: 48.36px
                            color: rgba(255, 255, 255, 0.5)
                            letter-spacing: 3px

            .homepage__statistic_left--notactive
                @include statistic_wrapper(16px 0px 0px 16px)
                display: flex
                justify-content: center
                align-items: center

                & span
                    font-family: Gilroy Bold
                    font-size: 50px
                    // font-size: 55px
                    font-weight: 700
                    line-height: 50px
                    text-align: center
                    color: rgba(255, 138, 54, 1)

            .homepage__statistic_right
                @include statistic_wrapper(0px 16px 16px 0px)
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                gap: 30px

                .homepage__statistic_adress
                    display: flex
                    flex-direction: column
                    gap: 3px

                    div
                        display: flex
                        justify-content: space-between
                        width: 100%

                        .homepage__copy_title
                            font-family: Gilroy
                            font-size: 22px
                            font-weight: 500
                            line-height: 25.89px
                            color: rgba(255, 255, 255, 0.4)

                    & > span
                        font-family: Gilroy
                        font-size: 22px
                        font-weight: 600
                        line-height: 26.25px
                        text-align: center
                        color: rgba(255, 255, 255, 1)

            .homepage__statistic_right--notactive
                @include statistic_wrapper(0px 16px 16px 0px)
                display: flex
                position: relative
                flex-direction: column
                align-items: center
                justify-content: center
                gap: 60px

                & > span
                    font-family: Gilroy
                    font-size: 30px
                    font-weight: 600
                    line-height: 35.79px
                    color: rgba(255, 255, 255, 0.7)

                .discord_svg
                    position: absolute
                    width: 32%
                    height: 36%
                    opacity: 0.18
                    top: 13%

    .homepage__girl
        position: absolute
        width: 43vw
        height: auto
        object-fit: contain
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        bottom: 0
        right: 21%
        max-height: 900px
        pointer-events: none
        animation: floatUpDown 3s ease-in-out infinite

    .homepage__girl_mobile
        display: none

    .homepage__mobileblock
        display: none

    .homepage__mobiletop
        display: none

@keyframes floatUpDown
    0%, 100%
        transform: translateY(0)

    50%
        transform: translateY(-20px)

@media screen and (max-width: 1550px)
    #homepage__wrapper
        // height: 1080px
        height: 100vh
        .homepage__content
            width: 91%

        .homepage__girl
            width: 56vw
            right: 0%
            max-height: 600px

@media screen and (max-width: 1024px)
    #homepage__wrapper
        // max-height: 1000px
        // height: 1000px
        height: 100vh
        .homepage__content
            width: 91%

            .homepage__statistic
                .homepage__statistic_left--notactive
                    & span
                        font-size: 50px
                        line-height: 40px
                .homepage__statistic_right--notactive
                    & > span
                        font-size: 27px

            .homepage__greeting
                display: flex
                margin-top: 142px

                .homepage__greeting_leftside
                    .homepage__desc
                        font-size: 43px

                    .homepage__title
                        font-size: 74px

                .homepage__statistic
                    margin-top: 56px
                    width: 589px
                    height: 206px

                    .homepage__statistic_left
                        gap: 5px
                        @include statistic_wrapper(16px 0px 0px 16px)

                        .homepage__statistic_left--title

                            .title
                                font-size: 30px

                            .status
                                font-size: 22px

                    .homepage__statistic_right
                        gap: 13px

                        .homepage__statistic_adress

                            div

                                .homepage__copy_title
                                    font-size: 19px
                                    font-weight: 500
                                    line-height: 22.36px

                                .homepage__copy_btn
                                    width: 23px
                                    height: 23px

                            & > span
                                font-size: 19px

                    .homepage__stistic_online--status
                        & > span
                            font-size: 22px

                        .homepage__statistic_value--wrapper
                            height: 66px

                            .online
                                font-size: 54px
                                letter-spacing: 1px

                            .offline
                                font-size: 34px
                                letter-spacing: 1px

        .homepage__girl
            left: 3%
            min-width: 525px

    .homepage__mobiletop
        display: block
        width: 100%
        height: 300px
        background-color: red

@media screen and (max-width: 768px)
    #homepage__wrapper
        // height: 1024px
        height: 100vh
        .homepage__content
            .homepage__statistic
                .homepage__statistic_left--notactive
                    & span
                        font-size: 40px
                        line-height: 35px
                .homepage__statistic_right--notactive
                    gap: 50px
                    & > span
                        font-size: 25px

                    .discord_svg
                        top: 13%

            .homepage__greeting
                .homepage__greeting_leftside
                    .homepage__desc
                        font-size: 37px
                        line-height: 39.96px
                        letter-spacing: -0.03em

                    .homepage__title
                        font-size: 64px
                        font-weight: 800
                        line-height: 63.36px

                .homepage__statistic
                    margin-top: 56px
                    width: 505px
                    height: 189px

                    .homepage__statistic_left
                        @include statistic_wrapper(16px 0px 0px 16px)
                        gap: 17px

                        .homepage__statistic_left--title
                            padding: 0 19px

                            .title
                                font-size: 26px
                                line-height: 31px

                            .status
                                font-size: 20px
                                line-height: 23px

                        .homepage__statistic_value--wrapper
                            height: 60px
                            .online
                                font-size: 50px
                                line-height: 60.45px

                            .offline
                                font-size: 30px
                                line-height: 36.27px

                        .homepage__stistic_online--status
                            & > span
                                font-size: 23px
                                line-height: 24.72px

                            .homepage__statistic_value--wrapper
                                height: 59px

                                .online
                                    font-size: 54px
                                    letter-spacing: 1px

                                .offline
                                    font-size: 34px
                                    letter-spacing: 1px

                    .homepage__statistic_right
                        gap: 17px
                        .homepage__statistic_adress
                            & > span
                                font-size: 17px

                            & div
                                .homepage__copy_title
                                    font-size: 18px
                                    line-height: 21.19px

                                .homepage__copy_btn
                                    width: 23px
                                    height: 23px

        .homepage__girl
            left: -11%

@media screen and (max-width: 550px)
    #homepage__wrapper
        height: 100vh
        // height: 800px
        background-size: cover
        background-position: right top

        .homepage__content
            .homepage__greeting
                justify-content: center
                .homepage__greeting_leftside
                    .homepage__desc
                        font-size: 25px
                        line-height: 27px
                        letter-spacing: -0.03em

                    .homepage__title
                        font-size: 42px
                        line-height: 41.58px

                .homepage__statistic
                    margin: 413px auto
                    width: 256px
                    height: 356px
                    gap: 5px
                    flex-direction: column
                    z-index: 1

                    .homepage__statistic_left
                        @include statistic_wrapper(10px 10px 0px 0px)
                        height: 49.5%
                        width: 100%

                        .homepage__statistic_left--title
                            padding: 0 19px

                            .title
                                font-size: 24px
                                line-height: 29.02px

                            .status
                                line-height: 23px
                                font-size: 20px

                        .homepage__statistic_value--wrapper
                            height: 60px
                            .online
                                font-size: 46px
                                line-height: 55.61px

                            .offline
                                font-size: 30px
                                line-height: 36.27px

                    .homepage__statistic_right
                        @include statistic_wrapper(0px 0px 10px 10px)
                        height: 49.5%
                        width: 100%

                        .homepage__statistic_adress
                            & > span
                                font-size: 17px

                            div
                                .homepage__copy_title
                                    font-size: 16px
                                    line-height: 18.56px

                                .homepage__copy_btn
                                    width: 18px
                                    height: 18px

                        .homepage__stistic_online--status
                            & > span

                            .homepage__statistic_value--wrapper
                                height: 66px

                                .online
                                    font-size: 54px
                                    letter-spacing: 1px

                                .offline
                                    font-size: 34px
                                    letter-spacing: 1px

                    .homepage__statistic_left--notactive
                        @include statistic_wrapper(10px 10px 0px 0px)
                        height: 49.5%
                        width: 100%
                        & span
                            font-size: 45px
                            line-height: 35px
                    .homepage__statistic_right--notactive
                        @include statistic_wrapper(0px 0px 10px 10px)
                        height: 49.5%
                        width: 100%
                        gap: 50px
                        & > span
                            font-size: 25px

                        .discord_svg
                            top: 13%

        .homepage__girl
            display: none

        .homepage__girl_mobile
            display: block
            position: absolute
            top: 35%
            width: 355px
            height: 516px
            object-fit: contain
            background-size: cover
            background-position: center
            background-repeat: no-repeat
            left: -35px
            animation: floatUpDown 3s ease-in-out infinite

    .homepage__mobileblock
        display: block
        height: 300px
        background-color: rgba(1, 3, 8, 1)
        box-shadow: 0px -108px 38px rgba(1, 3, 8, 1)
        position: relative

@media screen and (max-width: 360px)
    #homepage__wrapper
        .homepage__content
            .homepage__greeting
                .homepage__greeting_leftside
                    .homepage__desc
                        font-size: 5.8vw
                        line-height: 20.52px
                        letter-spacing: -0.01em
                        text-align: center

                    .homepage__title
                        font-size: 10.2vw
                        line-height: 32.67px
                        text-align: center

        .homepage__girl_mobile
            top: 44%
            width: 355px
            height: 416px
            left: -61px
