
@mixin footerBoxRoot($fontSize , $lineHeight , $margin)
    font-size: $fontSize
    line-height: $lineHeight
    margin: $margin

@mixin footerListItem($fontSize,$lineHeight )
    font-size: $fontSize
    line-height: $lineHeight

@mixin footerLogo($width, $height, $marginB)
    width: $width
    height: $height
    margin-bottom: $marginB

@media screen and (min-width: 220px)

    .footer__main_box
        font-family: "Gilroy"
        width: 100%
        height: 90px
        background-size: 100% 100%
        background-position: 100% 100%
        display: flex
        flex-direction: column
        justify-content: flex-end
        align-items: center
        z-index: 99
        position: relative
        .footer-img
            pointer-events: none
            z-index: 1
            position: absolute
            bottom: 0
            width: 480px
            object-fit: contain

            // width: 100%
            // height: 100px
            // object-fit: fill
        .footer__list
            display: flex
            // width: 222px
            width: max-content
            justify-content: center
            .footer__list_item
                width: 95px
                text-decoration: underline
                font-size: 7px
                font-weight: 600
                line-height: 6.93px
                letter-spacing: 0.02em
                text-align: left

                a
                    white-space: nowrap
                    text-decoration: none
                &:last-of-type
                    text-align: center
                &:hover,
                &:hover a
                    transition: color 0.5s
                    color: var(--main-color)
        .footer__box_root
            width: 100%
            display: flex
            flex-direction: column
            justify-content: center
            margin: 7px 0
            font-size: 7px
            font-weight: 600
            line-height: 10.29px
            letter-spacing: 0.02em
            text-align: center
            color: var(--footer-root-text-color)
        .footer__logo
            width: 24px
            height: 20px
            margin-bottom: 8px

@media screen and (min-width: 480px)

    .footer__main_box
        height: 140px
        .footer-img
            width: 768px
            // width: 100%
            // height: 150px
        .footer__list
            // width: 311px
            .footer__list_item
                white-space: nowrap
                width: 132px
                @include footerListItem(10px, 9.9px)
        .footer__box_root
            @include footerBoxRoot(10px , 14.7px , 10px 0)
        .footer__logo
            @include footerLogo(31px,25px, 12px)

@media screen and (min-width: 768px)

    .footer__main_box
        height: 233px
        .footer-img
            width: 1024px
            // width: 100%
            // height: 250px
        .footer__list
            // width: 437px
            .footer__list_item
                width: 185px
                @include footerListItem(14px, 13.86px)
        .footer__box_root
            @include footerBoxRoot(14px , 20.58px, 14px 0 16px 0)
        .footer__logo
            @include footerLogo(60px, 48px, 14.33px)

@media screen and (min-width: 1024px)

    .footer__main_box
        height: 280px
        .footer-img
            width: 1920px
            // width: 100%
            // height: 380px
        .footer__list
            // width: 558px
            width: max-content
            .footer__list_item
                width: 240px
                @include footerListItem(18px, 17.82px)
                &:last-of-type
                    text-align: center

        .footer__box_root
            @include footerBoxRoot(18px , 26.46px, 16px 0 22px 0)
        .footer__logo
            @include footerLogo(97px, 77px, 22px)

@media screen and (min-width: 1920px)

    .footer__main_box
        height: 380px
        .footer-img
            width: 2560px
            // width: 100%
            // height: 40vh
        .footer__list
            // width: 987px
            .footer__list_item
                width: 340px
                @include footerListItem(25.6px, 25.344px)
        .footer__box_root
            @include footerBoxRoot(25.6px , 37.6px, 30px 0 20px 0)
        .footer__logo
            @include footerLogo(130px, 104px, 30px)
@media screen and (min-width: 2560px)
    .footer__main_box
        height: 380px
        .footer-img
            width: 4096px
