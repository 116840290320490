@media screen and (min-width: 220px)
    .referral-wrapper
        width: 100%
        height: 85vh
        position: relative
        display: flex
        align-items: center
        justify-content: center
        // background-size: 100% 100%
        min-height: 1330px
        padding-top: 65px
        overflow: hidden
        box-shadow: inset 0px -20px 17px 0px #020824

        &::before
            width: 100%
            height: 100%
            content: ''
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            background-image: url("/assets/Images/backgroundliberty.png")
            background-size: cover
            background-position: right
            background-repeat: no-repeat
            opacity: 0.3
            z-index: 0
            box-shadow: inset 0px -20px 17px 15px #020824

        .referral-main-box
            position: absolute
            width: 88%
            height: 1228px

            .referral-box-info
                width: 100%
                height: 100%
                background: rgba(255, 255, 255, 0.05)
                backdrop-filter: blur(10px)
                border-radius: 10px
                display: flex
                flex-direction: column
                align-items: center
                justify-content: space-between
                .box-referral-info
                    position: relative
                    width: 100%
                    height: 307px
                    display: flex
                    justify-content: space-between

@media screen and (min-width: 480px)
    .referral-wrapper
        min-height: 965px
        padding-top: 100px
        box-shadow: inset 0px -20px 17px 0px #020824

        .referral-main-box
            width: 416px
            height: 853px
            .referral-box-info
                border-radius: 15px

@media screen and (min-width: 768px)
    .referral-wrapper
        min-height: 900px
        box-shadow: inset 0px -20px 17px 0px #020824

        .referral-main-box
            width: 695px
            height: 750px
            .referral-box-info
                border-radius: 20px

@media screen and (min-width: 1024px)
    .referral-wrapper
        min-height: 1173px
        box-shadow: inset 0px -20px 17px 0px #020824

        .referral-main-box
            width: 936px
            height: 1011px
            .referral-box-info
                border-radius: 24px

@media screen and (min-width: 1920px)
    .referral-wrapper
        width: 100%
        height: 85vh
        position: relative
        display: flex
        align-items: center
        justify-content: center
        background-size: 100% 100%
        min-height: 1420px
        box-shadow: inset 0px -20px 17px 0px #020824

        .referral-main-box
            position: absolute
            width: 1512px
            height: 1212px
            // DELETE AFTER BETA
            // filter: blur(30px)

            .referral-box-info
                width: 100%
                height: 100%
                background: rgba(255, 255, 255, 0.05)
                backdrop-filter: blur(10px)
                border-radius: 36px
                display: flex
                flex-direction: column
                align-items: center
                justify-content: space-between
                .box-referral-info
                    position: relative
                    width: 100%
                    height: 307px
                    display: flex
                    justify-content: space-between
