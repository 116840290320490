@media screen and (min-width: 220px)

    .ul-list-number-info
        width: 100%
        height: 200px
        display: flex
        flex-direction: column
        gap: 13px 0px
        overflow-y: scroll
        padding-right: 15px
        &::-webkit-scrollbar
            width: 4px

        &::-webkit-scrollbar-track
            background: #040922
            border-radius: 10px
        &::-webkit-scrollbar-thumb
            background: #FFFFFF73
            border-radius: 10px
    .li-list-number-info
        display: flex
        align-items: center

        .number
            font-family: "Gilroy Bold"
            font-size: 54px
            font-weight: 800
            line-height: 75%
            text-align: left
            background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0.5) 5.65%, rgba(153, 153, 153, 0) 116.94%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

        .text
            font-size: 11px
            font-weight: 500
            line-height: 12.21px
            text-align: left
            color: rgba(255, 255, 255, 0.5)

@media screen and (min-width: 480px)

    .ul-list-number-info
        width: 100%
        height: 100px
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 13px 17px
        overflow-y: scroll
        padding-right: 15px
        &::-webkit-scrollbar
            width: 4px

        &::-webkit-scrollbar-track
            background: #040922
            border-radius: 10px
        &::-webkit-scrollbar-thumb
            background: #FFFFFF73
            border-radius: 10px
    .li-list-number-info
        display: flex
        align-items: center

        .number
            font-family: "Gilroy Bold"
            font-size: 50px
            font-weight: 800
            text-align: left
            line-height: 77%
            background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0.5) 5.65%, rgba(153, 153, 153, 0) 116.94%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

        .text
            font-size: 10px
            font-weight: 500
            line-height: 11.1px
            text-align: left
            color: rgba(255, 255, 255, 0.5)

@media screen and (min-width: 768px)
    .ul-list-number-info
        width: 603px
        height: 132px
        margin: auto
        gap: 21px 4px
        margin-top: 48px
    .li-list-number-info
        display: flex
        align-items: center

        .number
            font-family: "Gilroy Bold"
            font-size: 70px
            font-weight: 800
            text-align: left
            line-height: 77%
            background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0.5) 5.65%, rgba(153, 153, 153, 0) 116.94%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

        .text
            font-size: 14px
            font-weight: 500
            line-height: 15.54px
            text-align: left
            color: rgba(255, 255, 255, 0.5)
@media screen and (min-width: 1024px)
    .ul-list-number-info
        width: 803px
        height: 178px
        margin: auto
        gap: 5px 16px
        margin-top: 25px
    .li-list-number-info
        display: flex
        align-items: center

        .number

            font-family: "Gilroy Bold"
            font-size: 115px
            font-weight: 800
            text-align: left
            line-height: 77%
            background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0.5) 5.65%, rgba(153, 153, 153, 0) 116.94%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

        .text
            font-size: 18px
            font-weight: 500
            line-height: 19.98px
            text-align: left
            color: rgba(255, 255, 255, 0.5)

@media screen and (min-width: 1920px)

    .ul-list-number-info
        margin: 0
        width: 517px
        height: 100%
        display: flex
        flex-direction: column
        gap: 26px 0px
        overflow-y: scroll
        padding-right: 15px
        &::-webkit-scrollbar
            width: 4px

        &::-webkit-scrollbar-track
            background: #040922
            border-radius: 10px
        &::-webkit-scrollbar-thumb
            background: #FFFFFF73
            border-radius: 10px
    .li-list-number-info
        display: flex
        align-items: center

        .number
            font-family: "Gilroy Bold"
            font-weight: 800
            text-align: left
            font-size: 115px
            line-height: 77%
            background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0.5) 5.65%, rgba(153, 153, 153, 0) 116.94%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

        .text
            font-size: 24px
            font-weight: 500
            line-height: 26.64px
            text-align: left
            color: rgba(255, 255, 255, 0.5)
