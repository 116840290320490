@media screen and (min-width: 220px)

    .box-img
        position: absolute
        top: 0
        left: -60px
        width: max-content
        .gromophone
            position: absolute
            width: 149px
            height: 127px
            left: -50px
            z-index: 1
            transform: rotate(-20deg)
        .box-one
            position: absolute

            z-index: 1

            .star1
                position: absolute
                width: 18px
                height: 18px
                top: -20px
                z-index: 1

            .star2
                position: absolute
                width: 26px
                height: 26px
                top: 120px
                left: 30px
                z-index: 1
                transform: rotate(45deg)
            .star3
                position: absolute
                width: 26px
                height: 26px
                top: 50px
                left: -150px
                filter: blur(3px)
                transform: rotate(30deg)
                z-index: 1

            .youtube
                position: absolute
                width: 37px
                height: 27px
                top: -10px
                left: 40px
                opacity: 1
                z-index: 1
            .telega
                position: absolute
                width: 33px
                height: 24px
                top: 20px
                left: 65px
                opacity: 0.8
                z-index: 1
            .twitter
                position: absolute
                width: 47px
                height: 40px
                top: 70px
                left: 50px
                opacity: 1
                z-index: 1
        .box-two
            position: absolute
            .discord
                position: absolute
                width: 53px
                height: 40px
                left: 0px
                top: -20px
            .star7
                position: absolute
                width: 40px
                height: 40px
                top: 140px
                left: -130px
                opacity: 0.8
                transform: rotate(15deg)
                filter: blur(3px)
            .star6
                position: absolute
                width: 20px
                height: 20px
                top: -30px
                left: -110px
                opacity: 0.8
                transform: rotate(60deg)
                filter: blur(3px)
            .star4
                position: absolute
                width: 35px
                height: 35px
                top: -25px
                left: 130px
                opacity: 0.5
            .star5
                position: absolute
                width: 38px
                height: 38px
                top: -30px
                left: -60px
                opacity: 0.5
                transform: rotate(45deg)
                filter: blur(3px)
@media screen and (min-width: 1024px)

    .box-img
        position: absolute
        top: 0
        left: -60px
        width: max-content
        .gromophone
            position: absolute
            width: 193px
            height: 165px
            left: -70px
            top: 60px
            z-index: 1
            transform: rotate(-20deg)
        .box-one
            position: absolute

            z-index: 1

            .star1
                position: absolute
                width: 23.4px
                height: 23.4px
                top: 30px
                z-index: 1

            .star2
                position: absolute
                width: 33.8px
                height: 33.8px
                top: 170px
                left: 30px
                z-index: 1
                transform: rotate(45deg)
            .star3
                position: absolute
                width: 33.8px
                height: 33.8px
                top: 100px
                left: -150px
                filter: blur(3px)
                transform: rotate(30deg)
                z-index: 1

            .youtube
                position: absolute
                width: 48.1px
                height: 35.1px
                top: 50px
                left: 60px
                opacity: 1
                z-index: 1
            .telega
                position: absolute
                width: 43px
                height: 31.2px
                top: 90px
                left: 80px
                opacity: 0.8
                z-index: 1
            .twitter
                position: absolute
                width: 61.1px
                height: 52px
                top: 130px
                left: 70px
                opacity: 1
                z-index: 1
        .box-two
            position: absolute
            .discord
                position: absolute
                width: 68.9px
                height: 52px
                left: 0px
                top: 30px
            .star7
                position: absolute
                width: 52px
                height: 52px
                top: 190px
                left: -130px
                opacity: 0.8
                transform: rotate(15deg)
                filter: blur(3px)
            .star6
                position: absolute
                width: 26px
                height: 26px
                top: 20px
                left: -110px
                opacity: 0.8
                transform: rotate(60deg)
                filter: blur(3px)
            .star4
                position: absolute
                width: 45.5px
                height: 45.5px
                top: -25px
                left: 130px
                opacity: 0.5
            .star5
                position: absolute
                width: 49.4px
                height: 49.4px
                top: 20px
                left: -60px
                opacity: 0.5
                transform: rotate(45deg)
                filter: blur(3px)

@media screen and (min-width: 1920px)

    .box-img
        position: absolute
        top: 0
        left: -60px
        width: 336px
        .gromophone
            position: absolute
            width: 291px
            height: 264px
            left: -150px
            top: 0px
            z-index: 1
            transform: rotate(-5deg)
        .box-one
            position: absolute

            z-index: 1

            .star1
                position: absolute
                width: 35px
                height: 35px
                top: -20px
                z-index: 1

            .star2
                position: absolute
                width: 50px
                height: 50px
                top: 200px
                left: 30px
                z-index: 1
                transform: rotate(45deg)
            .star3
                position: absolute
                width: 50px
                height: 50px
                top: 50px
                left: -150px
                filter: blur(3px)
                transform: rotate(30deg)
                z-index: 1

            .youtube
                position: absolute
                width: 73px
                height: 53px
                top: -10px
                left: 45px
                opacity: 1
                z-index: 1
            .telega
                position: absolute
                width: 65px
                height: 48px
                top: 50px
                left: 100px
                opacity: 0.8
                z-index: 1
            .twitter
                position: absolute
                width: 93px
                height: 79px
                top: 130px
                left: 80px
                opacity: 1
                z-index: 1
        .box-two
            position: absolute
            .discord
                position: absolute
                width: 106px
                height: 80px
                left: -60px
                top: -20px
            .star7
                position: absolute
                width: 80px
                height: 80px
                top: 180px
                left: -170px
                opacity: 0.8
                transform: rotate(15deg)
                filter: blur(3px)
            .star6
                position: absolute
                width: 40px
                height: 40px
                top: -30px
                left: -110px
                opacity: 0.8
                transform: rotate(60deg)
                filter: blur(3px)
            .star4
                position: absolute
                width: 70px
                height: 70px
                top: -30px
                left: 200px
                opacity: 0.5
            .star5
                position: absolute
                width: 76px
                height: 76px
                top: -30px
                left: -200px
                opacity: 0.5
                transform: rotate(45deg)
                filter: blur(3px)
