@use './answer'

@media screen and (min-width: 480px)
    #answer__wrapper
        min-height: 600px
        .answer__title
            width: 60%
            max-width: 300px
            font-size: 30px
            line-height: 29.7px
            margin-top: 10px
            margin-bottom: auto
        .answer__block_wrapper-flex
            display: flex
            flex-direction: column
            position: absolute

            .answer___block_wrapper
                display: flex
                flex-direction: column
                gap: 6px
                margin-bottom: 6px
                &:last-of-type
                    margin-bottom: 0px
                .answer__card_wrapper-open,
                .answer__card_wrapper-close

                    .answer__card_content
                        .answer__card_title
                            font-size: 16px
                            line-height: 16.8px

                        .answer__card_text
                            margin: 9px 0
                            font-size: 13px
                            line-height: 12px

                    .answer__card_btn
                        .answer__icon_tick
                            width: 7px
                            height: 13px

                .answer__card_wrapper-close
                    @include answer.answerCard(57.03px , 2, rgba(255, 255, 255, 1), -3px, rotate(0deg))

                .answer__card_wrapper-open
                    @include answer.answerCard(200px, -1, rgba(255, 138, 54, 1), -20px, rotate(90deg))

        .answer__decor_left
            position: absolute
            width: 178px
            height: 660px
            left: -50px
            top: -50px

        .answer__decor_right
            width: 178px
            height: 660px
            right: -50px
            top: -50px

@media screen and (min-width: 768px)
    #answer__wrapper
        min-height: 700px
        .answer__title
            width: 50%
            font-size: 40px
            line-height: 39.6px
            max-width: 380px
            margin-top: 15px
        .answer__block_wrapper-flex
            display: flex

            .answer___block_wrapper

                margin-bottom: 12px
                &:last-of-type
                    margin-bottom: 0px

                .answer__card_wrapper-open,
                .answer__card_wrapper-close

                    .answer__card_content
                        .answer__card_title
                            font-size: 18px
                            line-height: 18.9px

                        .answer__card_text
                            margin: 14px 0
                            font-size: 10px
                            line-height: 10.5px

                    .answer__card_btn
                        .answer__icon_tick
                            width: 10.4px
                            height: 17.83px

                .answer__card_wrapper-close
                    @include answer.answerCard(61px , 2, rgba(255, 255, 255, 1), -5px, rotate(0deg))

                .answer__card_wrapper-open
                    @include answer.answerCard(115px, -1, rgba(255, 138, 54, 1), -30px, rotate(90deg))

        .answer__decor_left
            display: none

        .answer__decor_right
            display: none

        .answer__decor_right768
            display: block
            position: absolute
            width: 178px
            height: 660px
            right: -5px
            top: -15px

        .answer__decor_left768
            display: block
            position: absolute
            width: 178px
            height: 660px
            left: -5px
            top: -15px

@media screen and (min-width: 1024px)
    #answer__wrapper
        min-height: 600px
        .answer__title
            width: 100%
            max-width: 100%
            margin-top: 100px
        .answer__block_wrapper-flex

            flex-direction: row
            margin-top: 40px
            .answer___block_wrapper
                display: flex
                flex-direction: column
                // max-width: 970px
                gap: 15px
                &:first-of-type
                    margin-right: 17px
        .answer__decor_right768
            width: 178px
            height: 660px
            right: -5px
            top: 0px

        .answer__decor_left768
            width: 178px
            height: 660px
            left: -5px
            top: 0px

@media screen and (min-width: 1920px)
    #answer__wrapper
        min-height: 900px
        .answer__title
            font-size: 70px
            line-height: 69.3px
            margin-top: 80px
        .answer__block_wrapper-flex
            margin-top: 85px

            .answer___block_wrapper
                max-width: 1510px
                gap: 36px
                &:first-of-type
                    margin-right: 36px

                .answer__card_wrapper-open,
                .answer__card_wrapper-close

                    .answer__card_content
                        .answer__card_title
                            font-size: 32px
                            line-height: 33.6px

                        .answer__card_text
                            margin: 14px 0
                            font-size: 20px
                            line-height: 21px

                    .answer__card_btn
                        .answer__icon_tick
                            width: 21px
                            height: 36px

                .answer__card_wrapper-close
                    @include answer.answerCard(123px , 2, rgba(255, 255, 255, 1), -10px, rotate(0deg))

                .answer__card_wrapper-open
                    @include answer.answerCard(235px, -1, rgba(255, 138, 54, 1), -60px, rotate(90deg))

        .answer__decor_left
            display: none

        .answer__decor_right
            display: none

        .answer__decor_right768
            width: 500px
            height: 900px
            right: -110px

        .answer__decor_left768
            width: 500px
            height: 900px
            left: -110px
