.btn__component
    width: 299px
    height: 76px
    border-radius: 12px
    background: rgba(255, 138, 54, 1)
    border: none
    font-family: Gilroy
    font-size: 36px
    font-weight: 700
    line-height: 43.52px
    color: rgba(255, 255, 255, 1)
    align-items: center
    text-align: center
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
    transition: box-shadow 0.5s
    cursor: pointer
    position: relative

    &:hover
        box-shadow: 0px 0px 27px 0px rgba(255, 138, 54, 0.5)

        span
            filter: drop-shadow(0px 0px 27.299999237060547px rgba(255, 255, 255, 0.5))

@media screen and (max-width: 1024px)
    .btn__component
        width: 258px
        height: 66px
        font-size: 30px
@media screen and (max-width: 768px)
    .btn__component
        width: 231px
        height: 62px
        font-size: 26px
