//Mobile first 320
#ref_section
    font-family: Gilroy
    width: 100%
    height: 156.77px
    display: flex
    justify-content: center
    align-items: center
    margin-top: 30px
    .ref_wrapper
        height: 114px
        width: 100%

        position: relative
        .ref_content
            height: 100%
            width: 100%
            display: flex
            flex-direction: column
            align-items: center
            justify-content: flex-end
            position: relative
            background: rgba(255, 255, 255, 0.08)
            background-position: bottom
            backdrop-filter: blur(15px)
            -webkit-backdrop-filter: blur(15px)
            align-items: center
            padding-bottom: 11px

            .ref_title
                position: absolute
                font-size: 20px
                font-weight: 900
                line-height: 24.3px
                text-align: center
                color: rgba(255, 138, 54, 1)
                text-transform: uppercase
                top: -13px
                font-family: Gilroy Bold
                left: 50%
                width: 100%
                transform: translate(-50%, 0px)
            .ref_description
                font-size: 8px
                font-weight: 600
                line-height: 10.4px
                text-align: center
                color: rgba(255, 255, 255, 0.5)
                width: 66%

            .ref_btn
                font-family: "Gilroy Bold"
                width: max-content
                height: 21.4px
                padding: 0 7px
                border-radius: 4px
                display: inline-flex
                align-items: center
                justify-content: center
                background: rgba(255, 138, 54, 1)
                font-size: 9px
                font-weight: 800
                line-height: 11.7px
                text-align: center
                border: 0
                color: white
                transition: box-shadow 0.5s ease , text-shadow 0.5s ease
                margin-top: 12px
                &:hover
                    box-shadow: 0px 0px 17.6px 0px rgba(255, 138, 54, 0.5)
                    text-shadow: 0px 0px 5px white

        .ref_decor1
            position: absolute
            left: -25px
            width: 96px
            height: 152.63px
            top: -25px
            z-index: 0
        .ref_decor2
            position: absolute
            left: -32px
            width: 90px
            height: 85.63px
            top: 23px
            z-index: 1
        .ref_decor3
            position: absolute
            right: -117px
            width: 190px
            height: 141.63px
            top: -11px
            z-index: 1

@media screen and (min-width: 480px)
    #ref_section
        height: 181.77px
        .ref_wrapper
            height: 124px
            .ref_content
                .ref_description
                    font-size: 10px
                    line-height: 13px
                    text-align: center
                    width: 60%
                .ref_btn

                    height: 32px
                    font-size: 14px
                    border-radius: 6px

            .ref_decor1
                width: 130px
                height: 173.63px
            .ref_decor2
                left: -37px
                width: 144px
                height: 145.63px
                top: 0px
            .ref_decor3
                right: -90px
                width: 200px
                height: 170.63px
                top: -24px

@media screen and (min-width: 768px)
    #ref_section
        height: 130.91px
        .ref_wrapper
            height: 84px
            .ref_content
                flex-direction: row
                bottom: 17px
                justify-content: center
                padding: 0 135px
                .ref_title
                    text-align: left
                    font-size: 28px
                    font-weight: 700
                    line-height: 34.02px
                    transform: translate(0%, 0%)
                    top: -40px
                    left: 0%

                .ref_description
                    position: relative
                    text-align: left
                    max-width: 450px
                .ref_btn
                    margin-top: 0
                    margin-left: 16px
                    font-size: 14px

            .ref_decor1
                width: 110px
                height: 133.63px
                left: -20px
            .ref_decor2
                left: -19px
                width: 160px
                height: 150.63px
                top: -34px
                rotate: 33deg
            .ref_decor3
                right: -70px
                width: 200px
                height: 150.63px
                top: -52px
@media screen and (min-width: 1024px)
    #ref_section
        height: 148.64px
        margin-top: 70px
        .ref_wrapper
            height: 93px
            .ref_content
                // max-width: 1920px
                padding: 0 170px
                margin: 0 auto
                .ref_title
                    top: -40px
                    font-size: 34px
                .ref_description
                    max-width: 600px
                    font-size: 13px
                    line-height: 16.9px
                    margin-top: 7px
                .ref_btn
                    height: 48.1px
                    font-size: 20px
                    padding: 0 8px
            .ref_decor1
                width: 113px
                height: 133.63px
                left: -20px
            .ref_decor2
                left: -24px
                width: 196px
                height: 180.63px
                top: -47px
                rotate: 33deg
            .ref_decor3
                width: 220px
                height: 166px
@media screen and (min-width: 1920px)
    #ref_section
        height: 278.1px
        .ref_wrapper
            height: 174px
            .ref_content
                .ref_title
                    top: -55px
                    font-size: 64px
                .ref_description
                    max-width: 833px
                    font-size: 24px
                    line-height: 31.2px
                .ref_btn
                    height: 90px
                    font-size: 36px
                    border-radius: 16px
                    padding: 0 22px
            .ref_decor1
                width: 213px
                height: 268.63px
                // left: 40px
                top: -50px
            .ref_decor2
                // left: 64px
                width: 300px
                height: 300px
                top: -71px
                rotate: 28deg
            .ref_decor3
                width: 360px
                height: 265px
@media screen and (min-width: 2500px)
    #ref_section
        .ref_wrapper
            .ref_decor1
                width: 213px
                height: 268.63px
                left: 160px
                top: -50px
            .ref_decor2
                left: 180px
                width: 300px
                height: 300px
                rotate: 28deg
            .ref_decor3
                height: 265px
                right: 160px
