
@media screen and (min-width: 220px)

    .socials__box
        margin-top: 15px
        font-family: "Gilroy"
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        position: relative

        background-size: auto 200%
        background-position: 22% 50%
        .splash
            position: absolute
            object-fit: contain
            width: 295px
            height: 105px
            bottom: 205px
            z-index: 1
            pointer-events: none
            .splash-img
                opacity: 0
                width: 100%
                height: 100%
        .socials__img_girl
            opacity: 0
            position: absolute
            bottom: -30px
            width: 285px
            height: 295px
            z-index: 2
            &.socials__img_anim
                opacity: 1
                animation: floarUpDown 3s ease-in-out infinite

        .socials_box_position
            position: relative
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            .socials__box_content
                display: flex
                flex-direction: column
                align-items: center
                .socials__title
                    font-family: "Gilroy Bold"
                    margin-bottom: 20px
                    text-shadow: 0px 0px 49.400001525878906px rgba(255, 255, 255, 0.6)
                .socials__list

                    min-width: 212px
                    display: grid
                    grid-template-columns: 1fr 1fr
                    flex-wrap: wrap
                    gap: 16px 16px
                    height: max-content
                    margin-bottom: 300px
                    height: max-content
                    .socials__item
                        opacity: 0
                        position: relative
                        font-size: 14px
                        font-weight: 700
                        line-height: 16px
                        text-align: center
                        width: 98px
                        height: 66px
                        display: flex
                        flex-direction: column
                        align-items: center
                        justify-content: space-between
                        border-bottom: 6px solid rgba(255, 255, 255, 0.15)
                        border-radius: 0px 0px 8px 8px
                        padding-bottom: 5px
                        .socials__list_icon
                            width: 45px
                            height: 45px
                            margin-bottom: 5px
                        &:hover
                            border-bottom: 6px solid var(--main-color)
                            color: var(--main-color)
                        &:hover .socials__item_gradient
                            transition: height 0.5s 0.5s
                            background: var(--socials-item-active-gradient)
                            animation: 0.7s gradientHeight ease
                        .socials__item_gradient
                            position: absolute
                            left: 0
                            bottom: 0
                            order-radius: 0px 0px 8px 8px
                            width: 100%
                            height: 100%
                            background: var(--socials-item-gradient)

@media screen and (min-width: 480px)

    .socials__box
        background-position: 20% 50%

        .splash
            width: 320px
            height: 117px
            bottom: 42%
        .socials__img_girl
            width: 312px
            height: 323px
            bottom: -20px
        .socials_box_position
            .socials__box_content
                .socials__title
                    font-size: 30px
                    line-height: 29.7px
                .socials__list
                    min-width: 232px
                    gap: 17.5px
                    margin-bottom: 346px
                    .socials__item
                        width: 107px
                        height: 72px
                        font-size: 16px
                        line-height: 15.84px

@media screen and (min-width: 768px)
    .socials__box
        padding: 0 40px
        align-items: center
        .socials__img_girl
            left: -160px
            top: 10px
            width: 454px
            height: 470px
        .splash
            width: 502px
            height: 155px
            bottom: 0
            right: -15%
        .socials_box_position
            width: 605.31px
            display: flex
            flex-direction: column
            align-items: flex-end
            .socials__box_content

                .socials__title
                    font-size: 40px
                    line-height: 39.6px
                .socials__list
                    gap: 24.68px
                    margin-bottom: 150px
                    .socials__item
                        width: 151px
                        height: 102px
                        font-size: 24px
                        line-height: 23.76px
                        justify-content: space-between
                        .socials__list_icon
                            width: 60px
                            height: 60px

@media screen and (min-width: 1024px)
    .socials__box
        padding: 0 40px
        align-items: center
        background-repeat: no-repeat
        background-size: auto 220%
        background-position: 20% 45%
        .socials__img_girl
            left: -7%
            top: -0px
            min-width: 586px
            min-height: 609px
            width: 57.22vw
            height: auto
            max-width: 655px
            max-height: 680px
        .splash
            width: 502px
            height: 185px
            bottom: 15%
            right: -8%
        .socials_box_position
            width: 937.37px
            .socials__box_content
                .socials__title
                    font-size: 40px
                    line-height: 39.6px
                .socials__list
                    gap: 24.68px
                    margin-bottom: 250px
                    .socials__item
                        font-size: 24px
                        line-height: 23.76px

@media screen and (min-width: 1920px)
    .socials__box
        padding: 0 220px
        align-items: center
        background-repeat: no-repeat
        .socials__img_girl
            left: -16%
            top: -9%
            min-width: 938px
            min-height: 974px
            width: 48.8vw
            height: auto
            max-width: 1014px
            max-height: 1053px
        .splash
            width: 870px
            height: 270px
            bottom: 12%
            right: -10%
        .socials_box_position
            width: 1512px
            .socials__box_content
                .socials__title
                    font-size: 70px
                    line-height: 69.3px
                    margin-bottom: 39px
                .socials__list
                    margin-bottom: 350px
                    gap: 39.59px
                    .socials__item

                        width: 239px
                        height: 162px
                        font-size: 36px
                        line-height: 35.64px
                        padding-bottom: 14px
                        .socials__list_icon
                            width: 90px
                            height: 90px

@media screen and (min-width: 2560px)
    .socials__box
        background-position: 0% 50%
        background-size: 100% 210%

@keyframes gradientHeight
    0%
        height: 0%
    100%
        height: 100%

@keyframes floarUpDown
    0%, 100%
        transform: translateY(0)
    50%
        transform: translateY(-20px)
