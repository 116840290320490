.modal__loader
    z-index: 99999
    position: absolute
    margin: 0 auto
    position: fixed
    left: 50%
    transform: translate(-50%, -50%)
    min-width: 500px
    min-height: 65px
    bottom: 5%
    border-radius: 15px
    display: flex
    align-items: center
    background: rgba(0, 0, 0, 0.5)
    border: 2px solid #ed8a19
    gap: 10px
    justify-content: center
    padding-left: 30px
    padding-right: 30px

    .loader-container
        z-index: 11
        align-items: center
        margin-right: auto
        position: absolute
        left: 20px

    .loader
        background-color: #ed8a19
        width: 37px
        height: 37px
        border-radius: 50%
        display: grid
        place-items: center
        animation: spin_412 5s infinite

        & .loader-svg
            transform: translateY(-2px) scale(0.7)

        .lb-svg
            background-repeat: no-repeat
            background-size: 100% 100%
            width: 23px
            height: 23px

    .modal__text
        font-family: Gilroy
        font-size: 25px
        font-weight: 700
        line-height: 30.11px
        color: #ffffff
        text-align: center

@keyframes spin_412
    0%
        transform: rotate(0) scale(1)

    50%
        transform: rotate(720deg) scale(1.3)

    100%
        transform: rotate(0) scale(1)

.animated
    animation: fade 5s forwards

@keyframes fade
    0%
        opacity: 0
    10%
        opacity: 1
    90%
        opacity: 1
    100%
        opacity: 0

@media screen and (max-width: 1185px)
    .modal__loader
        min-width: 400px
        min-height: 55px
        border-radius: 10px
        gap: 10px

        .loader-container
            left: 15px

        .loader
            background-color: #ed8a19
            width: 27px
            height: 27px
            border-radius: 50%
            display: grid
            place-items: center
            animation: spin_412 5s infinite

            .lb-svg
                width: 19px
                height: 19px

        .modal__text
            font-size: 22px

@media screen and (max-width: 700px)
    .modal__loader
        min-width: 250px
        min-height: 35px
        border-radius: 5px
        gap: 10px

        .loader-container
            left: 10px

        .loader
            background-color: #ed8a19
            width: 15px
            height: 15px
            border-radius: 50%
            display: grid
            place-items: center
            animation: spin_412 5s infinite

            .lb-svg
                width: 11px
                height: 11px

        .modal__text
            font-size: 14px
