
@import 'assets/rootMain.css'
@import 'assets/variables.css'

@mixin video_icon_arrow($width , $height)
    width: $width
    height: $height

@keyframes rotate
    from
        transform: rotate(1turn)

@media screen and (min-width: 220px )
    .video__main_box

        box-shadow: 0px -100px 49px 38px #020824
        width: 100%
        min-height: max-content
        position: relative
        background-size: auto 100%
        background-position: 40% 50%
        background-repeat: no-repeat
        transition: width 1s
        .video__img_photo
            background-size: contain
            background-repeat: no-repeat
            width: 100%
            height: 100%
            display: flex
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-40%, -30%)
        .video__box_content
            padding-right: 24px
            padding-left: 24px
            position: relative
            display: flex
            flex-direction: column
            overflow-x: hidden
            height: max-content
            overflow-y: hidden
            width: 100%
            .video__left_block
                opacity: 0
                position: relative
                display: flex
                align-items: flex-end
                justify-content: center
                min-height: 230px
                margin-bottom: 26px
                .video__img_people
                    position: absolute
                    width: 353.38px
                    height: 271px
                    left: 50%
                    top: 50%
                    transform: translate(-45%, -50%)

            .video__text-block
                width: 260px
                height: max-content
                z-index: 4
                .video__text_title
                    font-family: 'Gilroy Bold'
                    font-weight: 800
                    text-align: center
                    text-shadow: 0px 0px 49.400001525878906px rgba(255, 255, 255, 0.6)
                    margin-bottom: 6px
                    font-size: 24px
                    line-height: 23.76px

                .video__list
                    .video__list_item
                        font-size: 10px
                        font-weight: 500
                        line-height: 10.8px
                        text-align: center
                        margin-bottom: 5px
                        font-family: Gilroy
                        color: var(--pale-text)
                        &:last-of-type
                            margin-bottom: 0px
                        .video__list_item_special_text
                            color: var(--main-color)
        .video__right_block
            // padding-top: 0px
            opacity: 0
            width: 100%
            display: flex
            justify-content: center
            padding-bottom: 20px
            .video_gallery_block
                width: 272px
                min-height: 183px
                // height: max-content
                .video__video_link
                    display: flex
                    justify-content: center
                    width: 100%
                    margin-bottom: 7.72px
                    position: relative
                    z-index: 0
                    width: 100%
                    height: 138px
                    border-radius: 16px
                    overflow: hidden
                    align-items: center
                    // padding: 2rem
                    &::before
                        content: ''
                        position: absolute
                        z-index: -2
                        left: -50%
                        top: -50%
                        width: 200%
                        height: 200%
                        background-repeat: no-repeat
                        background-size: 40% 40%, 40% 40%
                        background-position: 0 0, 100% 0, 100% 100%, 0 100%
                        background-image: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)
                        animation: rotate 4s linear infinite

                    &::after
                        content: ''
                        position: absolute
                        z-index: -1
                        left: 6px
                        top: 6px
                        // width: calc(100% - 12px)
                        // height: calc(100% - 12px)
                        height: 100%
                        background: white
                        border-radius: 5px
                        animation: opacityChange 3s infinite alternate
                    .video_play_block
                        position: relative
                        width: 99%
                        height: 99%
                        -webkit-appearance: none
                        border: 1px solid #989898
                        // outline: 1px solid rgba(255, 255, 255, 0.3)
                        border-radius: 16px
                        display: flex
                        justify-content: center
                        align-items: center
                        background-size: 100% 100%
                        overflow: hidden
                        .video__icon_youTube
                            width: 14px
                            height: 14px
                            position: absolute
                            z-index: 5

                        &::after

                            content: ""
                            position: absolute
                            top: 0
                            left: 0
                            width: 100%
                            height: 100%
                            background: rgba(37, 37, 37, 0.7)
                            transition: opacity 0.3s ease
                            pointer-events: none
                            // border-radius: 16px

                        // &:hover::after
                        //     opacity: 0

                        .video__box_dark_background
                            position: absolute
                            z-index: 5
                            left: 0
                            top: 0
                            background: rgba(27, 27, 27, 0.8)
                            width: 100%
                            height: 100%
                    &:hover .video_play_block .video__icon_youTube
                        transform: scale(1.2)
                        filter: var(--home-video-drop-shadow)
                    &:hover::before ,
                    & .video_play_block:hover::after

                        opacity: 0
                        animation: none

                .video_list_img
                    display: flex
                    gap: 0 5.3px
                    box-sizing: content-box
                    .video_list_item
                        position: relative
                        width: 100%
                        height: 37px
                        border-radius: 8px
                        -webkit-appearance: none
                        // border: 1px solid #606060
                        outline: 1px solid rgba(255, 255, 255, 0.15)
                        overflow: hidden
                        background-size: 100% 100%
                        cursor: pointer
                        background-position: center
                        transition: background-size 0.5s
                        &:after
                            content: ""
                            position: absolute
                            top: 0
                            left: 0
                            width: 100%
                            height: 100%
                            background: rgba(37, 37, 37, 0.7)
                            transition: opacity 0.3s ease
                            pointer-events: none
                        &:hover::after
                            opacity: 0
                        &:hover
                            background-size: 110% 110%
                            // border: 2px solid #cc7722
                            outline: 2px solid rgba(255, 138, 54, 0.5)
                            filter: var(--home-video-drop-shadow)
    // .video__box_img_gallery
    //     position: relative
    //     display: flex
    //     justify-content: center
    //     align-items: flex-end
    //     margin: 0 28px

    //     .video__img_gallery
    //         width: 100%
    //         height: 100%
    //         border-radius: 16px
    //         &.video__mobile
    //             width: 100%
    //             height: 80vh
    //             object-fit: contain

    //     .video__exit
    //         background: transparent
    //         border: 0px
    //         font-weight: 800
    //         position: absolute
    //         right: 10px
    //         top: 10px
    //         color: white
    //         cursor: pointer
    //         font-size: 24px
    //         &:hover
    //             color: var(--main-color)
    // .video_img_box_right_arrow
    //     cursor: pointer
    //     .video__icon
    //         fill: var(--white-text)
    //         @include video_icon_arrow(15px, 30px)
    //     &:hover .video__icon
    //         fill: var(--main-color)
    // .video_img_box_left_arrow
    //     cursor: pointer
    //     display: flex
    //     justify-content: flex-end
    //     fill: var(--white-text)
    //     .video__icon
    //         @include video_icon_arrow(15px, 30px)
    //     &:hover .video__icon
    //         fill: var(--main-color)

@media screen and (min-width: 480px )

    .video__main_box
        box-shadow: 0px -100px 49px 38px #010723
        .video__box_content
            padding-right: 32px
            padding-left: 32px
            .video__left_block
                min-height: 300px
                margin-bottom: 28px
                .video__img_people
                    width: 475px
                    height: 365px
                    transform: translate(-50%, -50%)

            .video__text-block
                width: 365px
                .video__text_title
                    text-align: left
                    font-size: 30px
                    line-height: 29.7px
                .video__list
                    .video__list_item
                        font-size: 14px
                        line-height: 15.12px
                        text-align: left
        .video__right_block
            // padding-top: 0px
            margin-top: 15px

            .video_gallery_block
                width: 416px
                .video__video_link
                    height: 210px
                    margin-bottom: 11.7px
                    align-items: center
                    .video_play_block

                        .video__icon_youTube
                            width: 27px
                            height: 27px
                .video_list_img
                    gap: 0 8.8px
                    .video_list_item
                        height: 56.48px

@media screen and (min-width: 768px)

    .video__main_box
        width: 100%
        height: max-content
        .video__box_content
            padding-right: 102px
            padding-left: 102px
            .video__left_block
                min-height: 430px
                .video__img_people

                    width: 622px
                    height: 477px
                    transform: translate(-50%, -50%)

            .video__text-block
                width: 473px
                .video__text_title
                    font-size: 40px
                    line-height: 39.6px
                    margin-bottom: 5px
                .video__list
                    .video__list_item
                        font-size: 18px
                        line-height: 19.44px
                        margin-bottom: 8px
        .video__right_block
            // padding-top: 0px
            margin-top: 15px
            .video_gallery_block
                width: 564px
                .video__video_link
                    display: flex
                    justify-content: center
                    height: 280px
                    margin-bottom: 15.68px
                    .video_play_block
                        .video__icon_youTube
                            width: 36px
                            height: 36px
                .video_list_img
                    display: flex
                    gap: 0 11.94px
                    .video_list_item
                        height: 75.31px
    .video_img_box_right_arrow
        cursor: pointer
        .video__icon
            fill: var(--white-text)
            @include video_icon_arrow(15px, 30px)
        &:hover .video__icon
            fill: var(--main-color)
    .video_img_box_left_arrow
        cursor: pointer
        display: flex
        justify-content: flex-end
        fill: var(--white-text)
        .video__icon
            @include video_icon_arrow(15px, 30px)
        &:hover .video__icon
            fill: var(--main-color)
@media screen and (min-width: 1024px)
    .video__main_box
        margin-top: 20px
        display: flex
        justify-content: center
        align-items: center
        background-size: auto 120%
        background-position: 50% 35%
        box-shadow: 0px -100px 49px 38px #020823
        .video__box_content
            padding-right: 44px
            padding-left: 44px
            flex-direction: row
            justify-content: center
            align-items: flex-end
            .video__left_block
                min-height: 400px
                margin-bottom: 0px
                .video__img_people

                    width: 622px
                    height: 477px
                    transform: translate(-50%, -59%)

                .video__text-block
                    width: 473px
                    margin-right: 12px
                    .video__text_title
                        font-size: 40px
                        line-height: 39.6px
                        margin-bottom: 5px
                    .video__list
                        .video__list_item
                            font-size: 18px
                            line-height: 19.44px
                            margin-bottom: 8px
            .video__right_block
                // padding-top: 0px
                width: 464px
                margin-top: 15px
                .video_gallery_block
                    .video__video_link
                        display: flex
                        justify-content: center
                        height: 260px
                        margin-bottom: 15.68px
                        .video_play_block
                            .video__icon_youTube
                                width: 33px
                                height: 33px
                    .video_list_img
                        display: flex
                        gap: 0 9.83px
                        .video_list_item
                            height: 70px
    .video__box_img_gallery
        .video__img_gallery
            width: 900px
            height: 539px

@media screen and (min-width: 1920px)

    .video__main_box
        height: 900px
        color: var(--white-text)
        overflow-x: hidden
        overflow-y: visible
        margin-top: 0px
        .video__box_content
            padding-right: 203px
            padding-left: 203px
            padding-bottom: 20px
            height: 700px
            .video__left_block
                .video__img_people
                    position: absolute
                    width: 1068px
                    height: 766px
                    transform: translate(-50%, -75%)
                .video__text-block
                    width: 663px
                    height: 288px
                    z-index: 4
                    margin-bottom: 0px
                    margin-right: 69px

                    .video__text_title
                        font-size: 70px
                        line-height: 69.3px
                        margin-bottom: 26px
                    .video__list
                        .video__list_item
                            font-size: 26px
                            line-height: 28.08px
                            margin-bottom: 14px

            .video__right_block
                // margin-top: 15px
                // padding: 0px
                width: 779px
                .video_gallery_block
                    width: 100%
                    .video__video_link
                        height: 430px
                        margin-bottom: 24px
                        .video_play_block
                            .video__icon_youTube
                                width: 60px
                                height: 60px
                    .video_list_img
                        .video_list_item
                            width: 182px
                            height: 115px

@keyframes opacityChange

    opacity: 1
