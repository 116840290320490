.modal__background
    position: fixed
    z-index: 1000
    top: 0
    left: 0
    display: flex
    width: 100vw
    height: 100vh
    background: rgba(1, 7, 35, 0.7)
    display: flex
    justify-content: center
    align-items: center
