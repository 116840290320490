@mixin answerCard($height , $shadow-index, $textcolor, $btntop, $transformbtn)
    position: relative
    width: 272.09px
    max-height: $height
    background: rgba(255, 255, 255, 0.04)
    border: 1px solid rgba(167, 146, 146, 0.15)
    border-radius: 4px
    margin: 0 auto
    overflow: hidden
    padding: 5px 0px 0px 11px
    transition: border 0.5s , box-shadow 0.5s
    display: flex
    transition: max-height 0.5s ease-in-out
    cursor: pointer

    &::before
        content: ''
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 100%
        background: rgba(0, 0, 0, 0.2)
        box-shadow: inset 0px -8px 10px rgb(9 15 42)
        z-index: $shadow-index

    .answer__card_content
        .answer__card_title
            color: $textcolor
    .answer__card_btn
        transition: transform 0.5s , top 0.5s ease-in-out
        transform: $transformbtn
        top: $btntop

    @media screen and (min-width: 480px)
        width: 416px
        padding: 12px 0px 0px 15px
        border-radius: 6px

    @media screen and (min-width: 768px)
        width: 476px
        padding: 13px 0px 0px 19px
        border-radius: 8px
    @media screen and (min-width: 1920px)
        width: 738px
        padding: 20px 0px 0px 40px
        &::before
            box-shadow: inset 0px -20px 20px rgb(9, 15, 42)

#answer__wrapper
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    min-height: 430px
    justify-content: center
    box-shadow: 0px -0px 49px 38px #010723

    .answer__title
        font-family: Gilroy Bold
        font-size: 24px
        line-height: 23.76px
        color: #FFFFFF
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.6)
        text-align: center
        width: 100%
        max-width: 250px
        margin-bottom: 16px
    .answer__block_wrapper-flex

        .answer___block_wrapper
            display: flex
            flex-direction: column
            gap: 6px
            margin-bottom: 6px
            &:last-of-type
                margin-bottom: 0px
            // position: absolute
            // margin-top: 16px
            // width: 100%
            // display: grid
            // grid-template-columns: repeat(1, 1fr)

            .answer__card_wrapper-open,
            .answer__card_wrapper-close

                &:hover
                    border: 1px solid rgba(255, 138, 54, 1)
                    box-shadow: 0px 0px 22px 0px rgba(255, 138, 54, 0.5)
                    .answer__card_content
                        .answer__card_title
                            color: rgba(255, 138, 54, 1)
                    .answer__card_btn
                        .answer__icon_tick
                            transition: fill 0.5s
                            fill: rgba(255, 138, 54, 1)

                .answer__card_content
                    display: flex
                    flex-direction: column
                    width: 90%
                    .answer__card_title
                        font-family: Gilroy
                        font-size: 12px
                        font-weight: 700
                        line-height: 10.5px
                        text-align: start
                        transition: color 0.5s

                    .answer__card_text
                        position: relative
                        margin: 7px 0
                        font-family: Gilroy
                        font-size: 10px
                        font-weight: 500
                        line-height: 9px
                        text-align: left
                        color: rgba(255, 255, 255, 0.6)
                        z-index: 0

                .answer__card_btn
                    border: none
                    background: none
                    position: relative
                    margin: auto 0
                    z-index: 9
                    right: -10px

                    .answer__icon_tick
                        width: 4.58px
                        height: 8.5px
                        fill: white
                        transition: fill 0.5s

            .answer__card_wrapper-close
                @include answerCard(37.3px , 2, rgba(255, 255, 255, 1), -3px, rotate(0deg))

            .answer__card_wrapper-open
                @include answerCard(200px, -1, rgba(255, 138, 54, 1), -16px, rotate(90deg))

    .answer__decor_left
        position: absolute
        width: 300px
        height: 360px
        left: -129px
        top: 15px

    .answer__decor_right
        position: absolute
        width: 300px
        height: 360px
        right: -129px
        top: 15px

    .answer__decor_right768
        display: none

    .answer__decor_left768
        display: none
